import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

import React, { useState, useEffect } from "react";

import ReactMapGL, { Marker, Popup } from "react-map-gl";
import { Icon } from "@mui/material";

import "mapbox-gl/dist/mapbox-gl.css";

import "./MapComponent.css";
import { useSelector } from "react-redux";

import pmayLogo from "../../assets/images/pmay-logo.png";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; // Set your mapbox token here

function MapComponent(props) {
  const defaultViewPort = {
    latitude: 28.836927000000003,
    longitude: 78.79018300000001,
    zoom: 12,
  };

  const [viewPort, setViewport] = useState(defaultViewPort);
  const [markers, setMarkers] = useState([]);

  const siteStatus = useSelector((state) => state.siteStatus);

  function calculateCentroid(coords) {
    const numCoords = coords.length;

    if (numCoords === 0) {
      return defaultViewPort;
    }

    if (numCoords === 1) {
      return coords[0];
    }

    // Calculate the sum of latitudes and longitudes
    let sumLat = 0;
    let sumLong = 0;

    coords.forEach((coord) => {
      sumLat += coord.Latitude;
      sumLong += coord.Longitude;
    });

    // Calculate the average latitudes and longitudes
    const avgLat = sumLat / numCoords;
    const avgLong = sumLong / numCoords;

    return { latitude: avgLat, longitude: avgLong };
  }

  useEffect(() => {
    if (
      props.mapMarkers &&
      props.mapMarkers.length > 0 &&
      props.mapMarkers[0]?.Latitude &&
      props.mapMarkers[0]?.Longitude
    ) {
      const centroid = calculateCentroid(props.mapMarkers);
      setMarkers(props.mapMarkers);
      setViewport({
        ...viewPort,
        latitude: centroid.latitude,
        longitude: centroid.longitude,
      });
    }
  }, [props.mapMarkers]);

  return (
    <ReactMapGL
      {...viewPort}
      onMove={(evt) => setViewport(evt.viewState)}
      className="map-component-container"
      projection={"globe"}
      // reuseMaps
      attributionControl={false}
      mapStyle="mapbox://styles/mapbox/satellite-v9"
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
    >
      {markers &&
        markers[0]?.Latitude &&
        markers[0]?.Longitude &&
        markers.map((mapMarker) => (
           

           
          
          <Marker
            key={"main-marker-" + mapMarker.ID}
            longitude={mapMarker.Longitude}
            latitude={mapMarker.Latitude}
          >
          
           {siteStatus?.dataMap[mapMarker.status]?.type === "image" ? (
             <img
               src={pmayLogo}
               alt={siteStatus?.dataMap[mapMarker.status]?.label}
               style={{
                 width: "3rem",
                 height: "1.5rem",
               }}
             />
           ) : (
             <Icon
               title={siteStatus?.dataMap[mapMarker.status]?.label || mapMarker.status}
               baseClassName="fas"
               className={
                 mapMarker.status === "not-applicable"
                   ? "fa-circle-minus"
                   : "fa-location-dot"
               }
               fontSize="medium"
               style={{
                 color: "orange",
                 padding: "0.5rem",
                 borderRadius: "50%",
               }}
             />
           )}
         </Marker>
        ))}

      {/* show a warning message if no mapMarkers are available */}
      {props.mapMarkers && props.mapMarkers.length === 0 && (
        <div className="map-component-no-data">
          <h3>No Data Available</h3> 
        </div>
      )}
    </ReactMapGL>
  );
}

export default MapComponent;
