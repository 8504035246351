import React, { useEffect, useState, useCallback } from "react";
import "./MapView.css";
import "../../Sidebar/Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Tooltip, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { fetchMapViewSuccess, setMapValue } from "../../../redux/reducers/MapView";
import { selectConstructionCluster } from "../../../redux/reducers/mapMarkers";
import map1 from "../../../assets/images/map1.png";
import map2 from "../../../assets/images/map2.png";
import map3 from "../../../assets/images/map3.png";

const MapView = () => {
  const mapViewDetails = process.env.REACT_APP_MAP_STYLE_VIEW
    ? JSON.parse(process.env.REACT_APP_MAP_STYLE_VIEW.replace(/'/g, '"'))
    : [];

  const dispatch = useDispatch();
  const [showCluster, setShowCluster] = useState(false);
  const state = useSelector((state) => state.mapViews);
  const selectedClusters = useSelector((state) => state.markerCoordinates.selectedCluster);

  const styleArray = state?.styleArray || [];
  const value = state?.value || "";

  const handleClusterClick = () => {
    const updateClusterVal = {};
    for (let key in selectedClusters) {
      updateClusterVal[key] = !showCluster;
    }
    setShowCluster(!showCluster);
    dispatch(selectConstructionCluster(updateClusterVal));
  };

  // Ensure handleChange only dispatches if the new view is different
  const handleChange = useCallback(
    (event, newView) => {
      if (newView !== null && newView !== value) {
        dispatch(setMapValue(newView));
      }
    },
    [dispatch, value]
  );

  // Avoid repeatedly dispatching `fetchMapViewSuccess`
  useEffect(() => {
    if (mapViewDetails.length > 0 && state.styleArray.length === 0) {
      dispatch(fetchMapViewSuccess(mapViewDetails));
    }
  }, [dispatch, mapViewDetails, state.styleArray.length]);

  return (
    <div className="upload-image-btn-container circle-border">
      <div className="clusterView">
        <Icon
          className={"fa-solid fa-building-shield"}
          fontSize="medium"
          style={{
            color: "white",
            background: "rgba(0, 0, 0, 0.5)",
            padding: "0.5rem",
            borderRadius: "10%",
            animation: "colorChange 2s infinite",
          }}
          onClick={handleClusterClick}
          title="Show Sites / Clusters"
        />
      </div>

      <div className="MapViews">
        {styleArray.length > 0 ? (
          <ToggleButtonGroup
            orientation="vertical"
            value={value}
            exclusive
            onChange={handleChange}
          >
            {styleArray.map((mapStyle) => (
              <ToggleButton 
              key={mapStyle} 
              style={{ border: "2px solid green", padding: "5px" }} // Add padding to control spacing
              value={mapStyle} 
              aria-label={mapStyle}
            >
              {mapStyle === 'mapbox://styles/mapbox/navigation-night-v1' && (
                <Tooltip title="Night View">
                  <div style={{ width: "40px", height: "40px" }}> {/* Set fixed size for the container */}
                    <img 
                      src={map1} 
                      style={{ 
                        width: "100%", 
                        height: "100%", 
                        objectFit: "fill" 
                      }} 
                      alt="Map View Night" 
                    />
                  </div>
                </Tooltip>
              )}
            
              {mapStyle === 'mapbox://styles/mapbox/satellite-v9' && (
                <Tooltip title="Satellite View">
                  <div style={{ width: "40px", height: "40px" }}> {/* Set fixed size for the container */}
                    <img 
                      src={map3} 
                      style={{ 
                        width: "100%", 
                        height: "100%", 
                        objectFit: "fill" 
                      }} 
                      alt="Map View Satellite" 
                    />
                  </div>
                </Tooltip>
              )}
            
              {mapStyle === 'mapbox://styles/mapbox/navigation-day-v1' && (
                <Tooltip title="Day View">
                  <div style={{ width: "40px", height: "40px" }}> {/* Set fixed size for the container */}
                    <img 
                      src={map2} 
                      style={{ 
                        width: "100%", 
                        height: "100%", 
                        objectFit: "contain" 
                      }} 
                      alt="Map View Day" 
                    />
                  </div>
                </Tooltip>
              )}
            </ToggleButton>
            
            ))}
          </ToggleButtonGroup>
        ) : (
          <p>No map styles available.</p>
        )}
      </div>
    </div>
  );
};

export default MapView;
