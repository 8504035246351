import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const searchSiteid = createSlice({
  name: "searchSiteid",
  initialState,
  reducers: {
    fetchSiteidStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSiteidSuccess(state, action) {
      console.log("fetchDataSuccess");
      state.loading = false;
      state.data = action.payload;
      console.log("fetchDataSuccess data");
    },
    fetchSiteidFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchSiteidStart, fetchSiteidSuccess, fetchSiteidFailure } =
  searchSiteid.actions;
export default searchSiteid.reducer;
