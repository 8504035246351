import React, { useEffect, useState } from "react";
import "./ComingSoonBanner.css";

const ComingSoonBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Simulate a delay and then show the banner with animation
    const delay = setTimeout(() => {
      setShowBanner(true);
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, []);

  return (
    <div className={`coming-soon-banner ${showBanner ? "show" : ""}`}>
      <p className={`banner-text ${showBanner ? "show" : ""}`}>Coming Soon!</p>
    </div>
  );
};

export default ComingSoonBanner;
