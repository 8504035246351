import { Button } from "@mui/material";
import React, { useState } from "react";

import "./Login.css"
import logoLight from "../../assets/images/logo-horizontal-bg.png";
import logoDark from "../../assets/images/logo-horizontal.png";

import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "../../ThemeContext";

const LoginForm = () => {
  const { loginWithRedirect } = useAuth0();
  const { theme, toggleTheme } = useTheme();

  return (
    <div className="login-form-container">
      <div className="login-form-sub-container">
        <img src={theme === "light" ? logoLight : logoDark } alt="" />
        <h1>
          Welcome Back
        </h1>

        <p>Please click on login to view your dashboard</p>

        <button title="Login" defaultValue={"login"} onClick={loginWithRedirect}>
          Tap to Login
        </button>
      </div>

    </div>
  );
};

export default LoginForm;
