import React, { useState, useEffect } from "react";
import { Icon, TextField, Checkbox, Box, Button } from "@mui/material";

import "./SidebarPathTraversal.css";
import { useDispatch, useSelector } from "react-redux";
import { resetSelectedMarker } from "../../redux/reducers/selectedMarker";
import { setApplicationLoading } from "../../redux/reducers/application";
import {
  resetVerticalSidebar,
  setSelectedMarkers,
  setSidebarVisibility,
} from "../../redux/reducers/selectedSitesSidebar";
import SidebarSiteProgress from "../SidebarSiteProgress/SidebarSiteProgress";
import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchFieldMembersSuccess,
  selectFieldMembers,
} from "../../redux/reducers/fieldMembers";
import CustomDropdown from "./SelectDropdown";
import AddModal from "../AddModal/AddModal";
import DottedCircleLoader from "../Loader/DottedCircleLoader";
import Translate from "../Translate/Translate";

function SidebarPathTraversal(props) {
  const selectedMarker = useSelector(
    (state) => state.verticalSidebar.selectedSites,
  );
  const verticalSidebar = useSelector((state) => state.verticalSidebar);
  const zones = useSelector((state) => state.zones);
  const markerCoordinates = useSelector(
    (state) => state.markerCoordinates.data,
  );

  const fieldMembers = useSelector((state) => state.fieldMembers);

  const [siteTraversalForm, setSiteTraversalForm] = useState({});
  const [sheetLink, setSheetLink] = useState(null);
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSites, setSelectedSites] = useState(null);
  const [error, setError] = useState({});

  const [showFilter, setShowFilter] = useState(false);
  const [sidebarLevel, setSidebarLevel] = useState("sitesForPathTraversal");

  const [currentZone, setCurrentZone] = useState(null);
  const [searchFieldMemberText, setSearchFieldMemberText] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const config = {
    sitesForPathTraversal: {
      topbarHeader: "Sites for Path Traversal",
      searchBoxPlaceholder: "Search Location",
      middleContainer: {
        header: "Selected Sites",
      },
      footerButton: "Select Field Members",
      footerAction: "selectResources",
      backButtonAction: "sitesForPathTraversal",
    },
    selectResources: {
      topbarHeader: "Field Members",
      searchBoxPlaceholder: "Search Field Members",
      middleContainer: {
        header: "Field Members",
      },
      footerButton: "Enter Admin Email",
      footerAction: "numberOfWorkers",
      backButtonAction: "sitesForPathTraversal",
    },
    numberOfWorkers: {
      topbarHeader: "Email ID",
      // searchBoxPlaceholder: "Enter Number of People",
      searchBoxPlaceholder2: "Enter admin email to give access",
      footerButton: "Create Path Traversal",
      footerAction: "createPathTraversal",
      backButtonAction: "selectResources",
    },
    createPathTraversal: {
      topbarHeader: "Path Created & Saved",
      description:
        "You can view the progress of the path from the saved section in the side navigation",
      firstBoxLink: "",
      copyLinkText: "Copy link",
      footerButton: "Path Links are Ready !",
      footerAction: "openPath",
      backButtonAction: "numberOfWorkers",
      hideBackButton: false,
      showLinkBox: true,
    },
  };

  const dispatch = useDispatch();

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleSiteCheckboxChange = (id) => {
    // const currentCheckboxChange = {};
    // if (selectedMarker[id]) {
    //   currentCheckboxChange[id] = false;
    // }
    // dispatch(fetchSelectedMarkerSuccess(currentCheckboxChange));
    dispatch(setSelectedMarkers(id));
  };

  const handleFieldMembersCheckboxChange = (id) => {
    const currentCheckboxChange = {};
    if (fieldMembers.selected[id]) {
      currentCheckboxChange[id] = false;
    } else {
      currentCheckboxChange[id] = true;
    }

    dispatch(selectFieldMembers(currentCheckboxChange));
  };

  const handleSelectResourcesClick = (action) => {
    if (action === "createPathTraversal") {
      if (
        !validateEmailString(
          siteTraversalForm[config[sidebarLevel].searchBoxPlaceholder2] || "",
        )
      ) {
        alert("Please enter valid inputs. Check validation message");
        return;
      }

      handleCreatePathTraversalSubmit((downloadSheetLink) => {
        setSheetLink(downloadSheetLink);
        console.log("sheetLink ", sheetLink)
        setSidebarLevel(action);
      });
    } else if (action === "openPath") {
      console.log("copy Link to see the path !")
//      window.open(sheetLink, "_blank");
    } else if (action === "selectResources") {
      if (countTrueValues(selectedMarker) === 0) {
        return alert("Please select sites");
      } else {
        if (currentZone !== null) {
          fetchWorkers(currentZone, (data) => {
            dispatch(fetchFieldMembersSuccess(data));
            setSidebarLevel(action);
          });
        } else {
          zones.data.length && handleZoneChange(zones.data[0].Zone);
          setSidebarLevel(action);
        }
      }
    } else if (action === "numberOfWorkers") {
      if (countTrueValues(fieldMembers.selected) === 0) {
        return alert("Please select field members");
      } else if (
        countTrueValues(fieldMembers.selected) > countTrueValues(selectedMarker)
      ) {
        return alert(
          "Number of field members should not be more than total sites",
        );
      } else {
        setSidebarLevel(action);
      }
    } else {
      setSidebarLevel(action);
    }
  };

  function countTrueValues(obj) {
    return Object.values(obj).reduce(
      (count, value) => count + (value === true ? 1 : 0),
      0,
    );
  }

  const validateEmailString = (emailString) => {

    if (emailString === null || emailString === "") {
      const tempErrObj = {};
      tempErrObj[config[sidebarLevel].searchBoxPlaceholder2] =
        `Please enter atleast 1 valid email ID`;
      setError({
        ...error,
        ...tempErrObj,
      });
      return false;
    }

    const emails = emailString.split(",");

    for (let i = 0; i < emails.length; i++) {
      const email = emails[i].trim();

      // Validate each email using regex
      const emailRegex =
        /^[\w-]+(\.[\w-]+)*@[A-Za-z0-9]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
      if (!emailRegex.test(email)) {
        const tempErrObj = {};
        tempErrObj[config[sidebarLevel].searchBoxPlaceholder2] =
          `Invalid email at position ${i + 1} : ${email}`;
        setError({
          ...error,
          ...tempErrObj,
        });
        return false;
      }
    }

    const tempErrObj = error;
    delete tempErrObj[config[sidebarLevel].searchBoxPlaceholder2];
    setError(tempErrObj);
    return true;
  };

  const validateNumberOfPeople = (value) => {
    if (parseInt(value) > countTrueValues(selectedMarker) || value === "") {
      const tempErrObj = {};
      tempErrObj[config[sidebarLevel].searchBoxPlaceholder] =
        "Total people should not be more than total sites";
      setError({
        ...error,
        ...tempErrObj,
      });
      return false;
    }

    const tempErrObj = error;
    delete tempErrObj[config[sidebarLevel].searchBoxPlaceholder];
    setError(tempErrObj);
    return true;
  };

  const handlePathTraversalFormChange = (fieldType, value) => {
    if (sidebarLevel !== "numberOfWorkers") {
      console.debug("Not number of workers sidebar level");
      return;
    }

    if (
      fieldType === config[sidebarLevel].searchBoxPlaceholder &&
      !validateNumberOfPeople(value)
    ) {
      return;
    } else if (
      fieldType === config[sidebarLevel].searchBoxPlaceholder2 &&
      !validateEmailString(value)
    ) {
      return;
    }

    const tempObj = {};
    tempObj[fieldType] = value;

    setSiteTraversalForm({
      ...siteTraversalForm,
      ...tempObj,
    });
  };

  const handleSearchMemberChange = (searchValue) => {
    setSearchFieldMemberText(searchValue);
  };

  const { getAccessTokenSilently } = useAuth0();

  const getCommaSeparatedString = (obj) => {
    const trueKeys = Object.keys(obj).filter((key) => obj[key]);
    return trueKeys.join(",");
  };

  const createPathTraversalAPI = async (callback) => {
    const site_ids = getCommaSeparatedString(selectedMarker);
    const emp_ids = getCommaSeparatedString(fieldMembers.selected);
    const num_workers =
      siteTraversalForm[config.numberOfWorkers.searchBoxPlaceholder];
    const email_ids =
      siteTraversalForm[config.numberOfWorkers.searchBoxPlaceholder2];

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/trigger_tsp_with_workers?comma_seperated_site_ids=${site_ids}&mailing_id_to_share=${email_ids}&comma_seperated_emp_ids=${emp_ids}`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data received:", data);
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
        console.log(error)
      });
  };

  const handleCreatePathTraversalSubmit = (callback) => {
    setIsLoading(true);
    dispatch(setApplicationLoading(true));
    createPathTraversalAPI((data) => {
      setIsLoading(false);
      dispatch(setApplicationLoading(false));
      callback(data);
    });
  };

  const copyToClipboard = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      console.log("Indrlex is ", url)
      console.log("Text copied to clipboard successfully");
    } catch (error) {
      console.error("Failed to copy text to clipboard");
    }
  };

  const fetchWorkers = async (zone, callback) => {
    setIsLoading(true);

    // check for local storage data with key as workersData and value as {zone: data}
    const workersData = localStorage.getItem("workersData")
      ? JSON.parse(localStorage.getItem("workersData"))
      : {};
    if (workersData[zone]) {
      setIsLoading(false);
      callback(workersData[zone]);
    } else {
      workersData[zone] = [];
      localStorage.setItem("workersData", JSON.stringify(workersData));
    }

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });
    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/get_workers` +
      (zone ? `?zone=${zone}` : "");

    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        // Process the retrieved data here

        // update local storage data with key as workersData and value as {zone: data}
        workersData[zone] = data;
        localStorage.setItem("workersData", JSON.stringify(workersData));

        callback(data);
      })
      .catch((error) => {
        setIsLoading(false);
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
      });
  };

  useEffect(() => {
    const trueCount = countTrueValues(selectedMarker);

    setSelectedSites(trueCount);

    if (trueCount > 0) {
      dispatch(setSidebarVisibility(true));
    } else {
      console.log("Setting sidebar type to siteInfo");
      // dispatch(setSidebarVisibility(false));
      // dispatch(setSidebarType("siteInfo"));
    }
    const zoneObj = {
      zone1: { zoneName: 'zone-1', zoneCount: 0 },
      zone2: { zoneName: 'zone-2', zoneCount: 0 },
      zone3: { zoneName: 'zone-3', zoneCount: 0 },
      zone4: { zoneName: 'zone-4', zoneCount: 0 },
    };
    markerCoordinates.map((loc) => {
      if (selectedMarker[loc.ID]) {
        if (loc.Zone === 'zone-1')
          zoneObj.zone1.zoneCount += 1;
        else if (loc.Zone === 'zone-2')
          zoneObj.zone2.zoneCount += 1;
        else if (loc.Zone === 'zone-3')
          zoneObj.zone3.zoneCount += 1;
        else if (loc.Zone === 'zone-4')
          zoneObj.zone4.zoneCount += 1;
        else
          console.log("Selected site Id does not belong to any zone.")
      }
    })
    let modeOfZones = null;
    let maxValue = -Infinity;

    for (const [key, value] of Object.entries(zoneObj)) {
      console.log(key, " ", value.zoneCount)
      if (value.zoneCount > maxValue) {
        maxValue = value.zoneCount;
        modeOfZones = value.zoneName;
      }
    }
    console.log("Mode of the zones:", modeOfZones, " ", maxValue);
    if (modeOfZones !== null)
      handleZoneChange(modeOfZones)
  }, [selectedMarker]);

  const handleZoneChange = (zone) => {
    console.log("****handleZoneChange****** ", zone)
    setCurrentZone(zone);
    fetchWorkers(zone, (data) => {
      dispatch(fetchFieldMembersSuccess(data));
    });
  };

  const checkIfSearchValueExist = (obj) => {
    const someObj = {
      employee_id: obj.employee_id,
      first_name: obj.first_name,
      last_name: obj.last_name,
    };

    const locValues = Object.values(someObj);

    // Check if any of the locValues includes the searchFieldMemberText
    return locValues.some((value) =>
      value
        .toString()
        .toLowerCase()
        .includes(searchFieldMemberText.toLowerCase()),
    );
  };

  return verticalSidebar.showSidebar ? (
    verticalSidebar.sidebarType === "siteProgress" ||
      verticalSidebar.sidebarType === "siteInfo" ? (
      <SidebarSiteProgress />
    ) : (
      <div className="site-details-sidebar-container">
        <AddModal
          showModal={showModal}
          setShowModal={setShowModal}
          modalHeader={"Enter New Member Details"}
        />
        <div className="site-details-sidebar-top-container">
          <div className="site-details-sidebar-top-sub-container">
            {config[sidebarLevel].hideBackButton === true ? (
              <></>
            ) : (
              <div
                onClick={() =>
                  handleSelectResourcesClick(
                    config[sidebarLevel].backButtonAction,
                  )
                }
              >
                <Icon
                  title="Back"
                  baseClassName="fas"
                  className={"fa-angle-left cursor-pointer"}
                  fontSize="small"
                />
              </div>
            )}

            <h3>
              <Translate text={config[sidebarLevel].topbarHeader} />
            </h3>

            {/* <div
              onClick={() => {
                if (sidebarLevel === "createPathTraversal") {
                  dispatch(resetVerticalSidebar());
                  dispatch(resetSelectedMarker());
                  setSidebarLevel("sitesForPathTraversal");
                  return;
                }
                dispatch(setSidebarVisibility(false));
              }}
            >
              <Icon
                title="Close"
                baseClassName="fas"
                className={
                  "fa-solid " +
                  (sidebarLevel === "createPathTraversal"
                    ? "fa-xmark"
                    : "fa-angles-left") +
                  " cursor-pointer"
                }
                fontSize="small"
              />
            </div> */}
            <div>
              <Icon
                title="Close"
                baseClassName="fas"
                className="fa-solid fa-xmark cursor-pointer"
                fontSize="small"
                style={{ color: "red" }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevents the click from triggering the parent div's onClick
                  console.log("x-mark clicked");
                  dispatch(resetVerticalSidebar());
                  dispatch(resetSelectedMarker())
                  setSidebarLevel("sitesForPathTraversal")
                  return;
                }}
              />
              <Icon
                title="Arrow Left"
                baseClassName="fas"
                className="fa-solid fa-angles-left cursor-pointer"
                fontSize="small"
                onClick={(e) => {
                  e.stopPropagation();
                  console.log("angles clicked");
                  dispatch(setSidebarVisibility(false));
                }}
              />
            </div>
          </div>

          {sidebarLevel === "numberOfWorkers" && (
            <div className="site-details-sidebar-top-sub-filter-container">
              {config[sidebarLevel].middleContainer && (
                <div className="sidebar-filter-checkbox-container">
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid var(--border-color)",
                      color: "var(--text-color)",
                      borderRadius: 0,
                    }}
                  />

                  <div>
                    <Icon
                      title="Filter"
                      baseClassName="fas"
                      className={"fa-caret-down"}
                      fontSize="small"
                    />
                  </div>
                </div>
              )}

              {config[sidebarLevel].searchBoxPlaceholder && (
                <div
                  className={
                    "search-locations-container" +
                    (sidebarLevel === "numberOfWorkers"
                      ? " input-box-number-of-people"
                      : "") +
                    (error && error[config[sidebarLevel].searchBoxPlaceholder]
                      ? " border-red"
                      : "")
                  }
                >
                  <TextField
                    id="outlined-search-locations"
                    placeholder={config[sidebarLevel].searchBoxPlaceholder}
                    type="search"
                    fullWidth
                    focused
                    onInput={(evt) =>
                      handlePathTraversalFormChange(
                        config[sidebarLevel].searchBoxPlaceholder,
                        evt.target.value,
                      )
                    }
                    InputProps={{
                      startAdornment: config[sidebarLevel].middleContainer ? (
                        <Icon
                          title="Search"
                          baseClassName="fas"
                          className={"fa-magnifying-glass"}
                          fontSize="small"
                          color="var(--text-color)"
                          style={{ color: "var(--text-color)" }}
                        />
                      ) : (
                        <></>
                      ),
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {sidebarLevel === "selectResources" && (
          <div className="individual-site-container filters-container">
            <div
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <Icon
                title="Filter"
                baseClassName="fas"
                className={"fa-solid fa-filter cursor-pointer"}
                fontSize="small"
              />
            </div>

            {showFilter && (
              <div className="filter-dropdown">
                <CustomDropdown
                  value={
                    currentZone ||
                    (zones.data.length ? zones.data[0].Zone : "None")
                  }
                  options={zones.data.map((zone) => zone.Zone)}
                  onChange={handleZoneChange}
                  isMultiSelect={false}
                />
              </div>
            )}

            <div className="search-field-members">
              <TextField
                id="search-field-members"
                placeholder={config[sidebarLevel].searchBoxPlaceholder2}
                type="search"
                fullWidth
                defaultValue=""
                value={searchFieldMemberText || ""}
                focused
                onInput={(evt) => handleSearchMemberChange(evt.target.value)}
                InputProps={{
                  startAdornment: config[sidebarLevel].middleContainer ? (
                    <Icon
                      title="Search"
                      baseClassName="fas"
                      className={"fa-magnifying-glass"}
                      fontSize="small"
                      color="var(--text-color)"
                      style={{ color: "var(--text-color)" }}
                    />
                  ) : (
                    <></>
                  ),
                }}
              />
            </div>
          </div>
        )}
        {sidebarLevel === "numberOfWorkers" &&
          error &&
          error[config[sidebarLevel].searchBoxPlaceholder] && (
            <div className="error-message-box">
              <Translate
                text={error[config[sidebarLevel].searchBoxPlaceholder]}
              />{" "}
              ({countTrueValues(selectedMarker)})
            </div>
          )}
        <div className="site-details-sidebar-bottom-container">
          {config[sidebarLevel].searchBoxPlaceholder2 && (
            <div
              className={
                "search-locations-container enter-email-container" +
                (error && error[config[sidebarLevel].searchBoxPlaceholder2]
                  ? " border-red"
                  : "")
              }
            >
              <TextField
                id="enter-email-list"
                placeholder={config[sidebarLevel].searchBoxPlaceholder2}
                type="search"
                fullWidth
                focused
                onInput={(evt) =>
                  handlePathTraversalFormChange(
                    config[sidebarLevel].searchBoxPlaceholder2,
                    evt.target.value,
                  )
                }
                InputProps={{
                  startAdornment: config[sidebarLevel].middleContainer ? (
                    <Icon
                      title="Search"
                      baseClassName="fas"
                      className={"fa-magnifying-glass"}
                      fontSize="small"
                      color="var(--text-color)"
                      style={{ color: "var(--text-color)" }}
                    />
                  ) : (
                    <></>
                  ),
                }}
              />
            </div>
          )}

          {sidebarLevel === "numberOfWorkers" &&
            error &&
            error[config[sidebarLevel].searchBoxPlaceholder2] && (
              <div className="error-message-box error-message-box-2">
                <Translate
                  text={error[config[sidebarLevel].searchBoxPlaceholder2]}
                />
              </div>
            )}

          {config[sidebarLevel]?.middleContainer?.header ? (
            <div className="middle-container-header">
              <h4>
                <Translate text={config[sidebarLevel].middleContainer.header} />{" "}
                ({selectedSites})
              </h4>

              {sidebarLevel === "selectResources" && (
                <h5 onClick={() => setShowModal(true)}>
                  <Translate text="Add Field Members" />
                </h5>
              )}
            </div>
          ) : (
            <></>
          )}

          {sidebarLevel === "selectResources" &&
            (isLoading ? (
              <DottedCircleLoader />
            ) : (
              <div className="selected-sites-container">
                {fieldMembers.data
                  .filter((loc) => {
                    if (
                      !fieldMembers.selected[loc.employee_id] &&
                      searchFieldMemberText
                    ) {
                      return checkIfSearchValueExist(loc);
                    }
                    return true;
                  })
                  .map((loc) => (
                    <div
                      key={loc.employee_id}
                      className="individual-site-container"
                    >
                      <div>
                        <Checkbox
                          checked={fieldMembers.selected[loc.employee_id]}
                          onChange={() =>
                            handleFieldMembersCheckboxChange(loc.employee_id)
                          }
                          style={{
                            backgroundColor: "transparent",
                            color: "var(--text-color)",
                            borderRadius: 0,
                            padding: 0,
                          }}
                        />
                      </div>
                      <div className="site-details-box">
                        <h4>
                          <Translate
                            text={loc.first_name + " " + loc.last_name}
                          />
                        </h4>
                        <p>
                          {loc.employee_id} &#8226;
                          <Translate text={loc.zone} />
                        </p>
                        {/* <p>{loc.zone}</p> */}
                        <p>
                          <Translate text={"Active Assigned Sites"} /> :{" "}
                          {loc.num_active_sites}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            ))}

          {sidebarLevel === "sitesForPathTraversal" ? (
            <div className="selected-sites-container">
              {markerCoordinates.map(
                (loc) =>
                  selectedMarker[loc.ID] && (
                    <div key={loc.ID} className="individual-site-container">
                      <div>
                        <Checkbox
                          checked={selectedMarker[loc.ID]}
                          onChange={() => handleSiteCheckboxChange(loc.ID)}
                          inputProps={{ "aria-label": "controlled" }}
                          style={{
                            backgroundColor: "transparent",
                            // color: "var(--text-color)",
                            borderRadius: 0,
                            padding: 0,
                          }}
                        />
                      </div>

                      <div className="site-details-box">
                        <h4>{loc.ID}</h4>
                        <p>
                          <Translate text={loc.Address} />
                        </p>
                      </div>
                    </div>
                  ),
              )}
            </div>
          ) : (
            <></>
          )}

          {config[sidebarLevel].showLinkBox && (
            <div className="link-box-container">
              <p>
                <Translate text="You can view the progress of the path from the saved section in the side navigation" />
              </p>
              {/* <div id="real-link-box">{sheetLink}</div> */}
              <div id="real-link-box">
                {sheetLink.map((link, index) => {
                  const [url, name] = link.split(",");
                  return (
                    <div
                      key={index}
                      className={`link-container ${index % 2 === 0 ? "light-background" : "dark-background"}`}
                    >
                      <div className="link-text">{url}</div>
                      <div className="worker-name-container">
                        <span className="worker-name">{name}</span>
                        <span id="copy-path-traversal-link-btn" onClick={() => copyToClipboard(url)} className="copy-icon">
                          📋
                          <span className="tooltip-text">Copy Link</span>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* <div id="copy-path-traversal-link-btn" onClick={copyToClipboard}>
                <Translate text="Copy link" />
              </div> */}
            </div>
          )}
        </div>

        {isLoading && sidebarLevel === "numberOfWorkers" ? (
          <>
            <DottedCircleLoader />
          </>
        ) : (
          <></>
        )}

        {selectedSites > 0 ? (
          <div className="site-details-sidebar-footer">
            <Box className="box-container box-create-path-traversal">
              <Button
                className="create-path-traversal"
                onClick={() =>
                  handleSelectResourcesClick(config[sidebarLevel].footerAction)
                }
              >
                <Translate text={config[sidebarLevel].footerButton} />
              </Button>
            </Box>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  ) : (
    selectedSites > 0 && (
      <div className="site-details-sidebar-container site-details-sidebar-container-collapse">
        <div
          onClick={() => {
            dispatch(setSidebarVisibility(true));
          }}
        >
          <Icon
            title="Open"
            baseClassName="fas"
            className={"fa-solid fa-angles-right cursor-pointer"}
            fontSize="small"
          />
        </div>
      </div>
    )
  );
}

export default SidebarPathTraversal;
