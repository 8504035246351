import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  dataMap: {},
  selected: {},
  loading: false,
  error: null,
};

const fieldMembers = createSlice({
  name: "fieldMembers",
  initialState,
  reducers: {
    fetchFieldMembersStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchFieldMembersSuccess(state, action) {
      console.log("fetchDataSuccess zones success");
      state.loading = false;
      
      const tempData = action.payload;
      const tempDataMap = {};
      
      for (let i = 0; i < tempData.length; i++) {
        tempDataMap[tempData[i].employee_id] = tempData[i];
      }

      state.data = tempData;
      state.dataMap = tempDataMap;
      console.log("fetchDataSuccess zones success data");
    },
    selectFieldMembers(state, action) {
      state.loading = false;
      state.selected = {
        ...state.selected,
        ...action.payload,
      };
    },
    fetchFieldMembersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resetFieldMembers(state) {
      state.data = [];
      state.dataMap = {};
      state.selected = {};
      state.loading = false;
      state.error = null;
    }
  },
});

export const {
  fetchFieldMembersStart,
  fetchFieldMembersSuccess,
  selectFieldMembers,
  fetchFieldMembersFailure,
  resetFieldMembers,
} = fieldMembers.actions;
export default fieldMembers.reducer;
