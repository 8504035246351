import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import helperFunctions from "../../utils/helper";

const initialState = {
  data: {},
  showSidebar: false,
  sidebarLevel: "sitesForPathTraversal",
  sidebarType: 'siteInfo',
  currentSelectedSite: null,
  selectedSites: {},
  siteImage: null,
  loading: false,
  error: null,
  currentGroundInspectionImages: [],
};

const verticalSidebar = createSlice({
  name: "verticalSidebar",
  initialState,
  reducers: {
    setSidebarVisibility(state, action) {
      state.showSidebar = action.payload === true;
    },
    resetVerticalSidebar(state, action) {
      state.data = {};
      state.showSidebar = false;
      state.sidebarLevel = "sitesForPathTraversal";
      state.sidebarType = 'siteInfo';
      state.currentSelectedSite = null;
      state.selectedSites = [];
      state.siteImage = null;
      state.loading = false;
      state.error = null;
    },
    setSelectedMarkers(state, action) {
      const siteID = action.payload;
      state.selectedSites[siteID] = !state.selectedSites[siteID];

      if (helperFunctions.countTrueValues(state.selectedSites) === 0) {
        console.log("Resetting selectedSites as no site is selected");
        state.selectedSites = {};
        state.sidebarType = "siteInfo"
      } else {
        console.log("Setting sidebarType to pathTraversal");
        state.sidebarType = "pathTraversal";
      }
    },
    setSidebarType(state, action) {
      try {
        if (action.payload === null || action.payload === undefined) {
          console.log("Skipping setSidebarType as payload is null or undefined");
          return;
        }

        if (action.payload === "pathTraversal") {
          state.sidebarType = action.payload;
          return;
        }

        let isSiteInfo = false;
        let tempSite = null;

        if (action.payload.siteInfo) {
          tempSite = JSON.parse(JSON.stringify(action.payload.siteInfo));
          isSiteInfo = true;
        }

        if (
          action.payload.siteImage !== null &&
          action.payload.siteImage !== undefined &&
          action.payload.siteImage !== "" &&
          isSiteInfo &&
          tempSite.ID !== state.currentSelectedSite.ID
        ) {
          console.log(
            "Skipping siteImage update for site: ",
            " as it is not the current selected site ID: "
          );
          return;
        }
  
        state.sidebarType = action.payload.sidebarType;
        state.currentSelectedSite = tempSite || state.currentSelectedSite;
  
        if (action.payload.siteImage !== null && action.payload.siteImage !== undefined) {
          state.siteImage = action.payload.siteImage;
        }
      } catch (error) {
        console.error("Error setting sidebar type in redux: ");
        toast.error("Error setting sidebar type in redux");
      }
    },
    setSiteImage(state, action) {
      state.siteImage = action.payload;
    },
    changeSiteStatus(state, action) {
      const tempSite = state.currentSelectedSite;
      tempSite.status = action.payload;
      state.currentSelectedSite = tempSite;
    },
    updateSiteInfoAttribute(state, action) {
      const tempSite = state.currentSelectedSite;
      tempSite[action.payload.attribute] = action.payload.value;
      state.currentSelectedSite = tempSite;
      console.log("current Site Info Updated in redux");
    },
    updateCurrentGroundInspectionImages(state, action) {
      if (action.payload === null || action.payload.images === null || action.payload.images === undefined) {
        console.log("Skipping updateCurrentGroundInspectionImages as payload is null or undefined");
        return;
      }

      if (action.payload.siteID !== state.currentSelectedSite.ID) {
        console.log(
          "Skipping updateCurrentGroundInspectionImages as payload ID: ",
          " does not match currentSelectedSite ID: "
        );
        return;
      }

      state.currentGroundInspectionImages = action.payload.images;
      console.log("state.currentGroundInspectionImages ",state.currentGroundInspectionImages)
    }
  },
});

export const {
  setSidebarVisibility,
  setSelectedMarkers,
  setSidebarType,
  changeSiteStatus,
  resetVerticalSidebar,
  setSiteImage,
  updateSiteInfoAttribute,
  updateCurrentGroundInspectionImages,
} = verticalSidebar.actions;
export default verticalSidebar.reducer;
