import { ThemeProvider, createTheme } from "@mui/material";
import "./App.css";
import React, { useState } from "react";
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import Child from "./Child";
import "./styles.css";

import { CustomThemeProvider, useTheme } from "./ThemeContext";
import { Toaster } from "react-hot-toast";

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Poppins"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CustomThemeProvider>
          <Toaster
            position="bottom-center"
            reverseOrder={false}
          />
          <Child />
        </CustomThemeProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
