import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const clusters = createSlice({
  name: "clusters",
  initialState,
  reducers: {
    fetchClustersStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchClustersSuccess(state, action) {
      console.log("fetchDataSuccess clusters success");
      state.loading = false;
      
      const tempData = action.payload;
      state.data = tempData;
      console.log("fetchDataSuccess clusters success data");
    },
    fetchClustersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchClustersStart,
  fetchClustersSuccess,
  fetchClustersFailure,
} = clusters.actions;
export default clusters.reducer;
