import React from "react";
import { Checkbox } from "@mui/material";
import Translate from "../Translate/Translate";


const CustomDropdown = ({ value, options, onChange, isMultiSelect }) => {

  const handleChange = (event) => {
    const newValue = isMultiSelect
      ? event.target.value
      : event.target.value !== ""
      ? event.target.value
      : null;

    onChange(newValue);
  };

  return (
    <>
      {options.sort((a,b) => a > b).map((objValue) => {
        return (
          <div
            key={objValue}
            className="site-info-status-select-sub-container cursor-pointer"
            // style={{ color: statusObj.color }}
            onClick={() => {
              handleChange({
                target: {
                  value: objValue,
                },
              });
            }}
          >
            <Checkbox
              onChange={() =>
                handleChange({
                  target: {
                    value: objValue,
                  },
                })
              }
              checked={objValue === value}
            />

            <p className="m-0" style={{ flexGrow: 1 }}>
              <Translate text={`Only show ${objValue} members`} />
            </p>
          </div>
        );
      })}
    </>
  );
};

export default CustomDropdown;
