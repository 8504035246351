import React, { useState, useEffect } from "react";
import "./DottedCircleLoader.css"; // Import the CSS file for BucketFillUpBox

const DottedCircleLoader = ({ isFullPage }) => {
  return (
    <div
      className={
        "dotted-circle-loader" + (isFullPage ? " full-page-loader" : "")
      }
    >
      <div className="loader" />
    </div>
  );
};

export default DottedCircleLoader;
