import { createSlice } from "@reduxjs/toolkit";

const selectedLanguage = localStorage.getItem("selectedLanguage") || "en";

const initialState = {
  data: null,
  language: selectedLanguage, // default language
  isAuthenticated: false,
  loading: false,
  error: null,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchUserStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUserSuccess(state, action) {
      console.log("fetchUserSuccess success payload");
      state.loading = false;
      state.data = action.payload;
      console.log("fetchUserSuccess success data");
    },
    setUser(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    setIsAuthenticated(state, action) {
      state.loading = false;
      state.isAuthenticated = action.payload;
    },
    fetchUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    selectLanguage(state, action) {
      localStorage.setItem("selectedLanguage", action.payload);
      state.language = action.payload;
    },
  },
});

export const { setUser, setIsAuthenticated, selectLanguage } = user.actions;
export default user.reducer;
