import { MenuItem, Select, FormControl, Checkbox, ListItemText } from "@mui/material";
import { BarChart, ResponsiveContainer, Bar, XAxis, YAxis, Tooltip as RechartsTooltip, Legend as RechartsLegend } from 'recharts';
import bgImage from "../../assets/images/newDesign.jpg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const Page2 = ({ siteData, Zones, Status, Sizes }) => {
    const sizes = useSelector((state) => state.markerCoordinates.sizes); // Assuming 'tableData' is stored in the Redux store

    const [filterData, setFilterData] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState(sizes);
    const [chartHeight, setChartHeight] = useState(620);
    
    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth <= 1300) setChartHeight(490);
            else if (screenWidth <= 1340) setChartHeight(510);
            else if (screenWidth <= 1499) setChartHeight(600);
            else if (screenWidth <= 1399) setChartHeight(510);
            else if (screenWidth <= 1500) setChartHeight(650);
            else if (screenWidth <= 1599) setChartHeight(650);
            else if (screenWidth <= 1600) setChartHeight(690);
            else if (screenWidth <= 1650) setChartHeight(690);
            else if (screenWidth <= 1699) setChartHeight(700);
            else if (screenWidth <= 1760) setChartHeight(740);
            else if (screenWidth <= 1799) setChartHeight(750);
            else if (screenWidth <= 1850) setChartHeight(860);
            else if (screenWidth <= 1899) setChartHeight(790);
            else if (screenWidth <= 1920) setChartHeight(780);
            else if (screenWidth <= 1999) setChartHeight(780);
            else setChartHeight(780);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const CustomArrowIcon = () => (
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10H7Z" fill="white" />
        </svg>
    );

    const orderedStatus = [
        'new', // White group
        'New-Assigned', 'ground-inspection', 're-inspection', // Yellow group
        'demolished', 'sealed', 'court-case', // Red group
        'pmay', 'govt-campus', 'not-applicable', 'map-submitted', 'map-issued', // Green group
        'notice', 'do-issued',  // Orange group
    ];


    const FilteredData = () => {
        const zoneSummaryMap = {};
    
        siteData.forEach((site) => {
            const { Zone, status, size } = site;
    
            if (selectedSizes.length === 0 || selectedSizes.includes(size)) {
                if (!zoneSummaryMap[Zone]) zoneSummaryMap[Zone] = { zone: Zone };
    
                if (Status.includes(status)) {
                    if (!zoneSummaryMap[Zone][status]) zoneSummaryMap[Zone][status] = 0;
                    zoneSummaryMap[Zone][status] += 1;
                }
            }
        });
    
        const sortedZones = Object.values(zoneSummaryMap).sort((a, b) => {
            const zoneA = parseInt(a.zone.split('-')[1]);
            const zoneB = parseInt(b.zone.split('-')[1]);
            return zoneA - zoneB;
        });
    
        return sortedZones.filter((zoneSummary) => Object.keys(zoneSummary).length > 1);
    };
    

    const handleSizeChange = (event) => {
        const { value } = event.target;
        setSelectedSizes(typeof value === 'string' ? value.split(',') : value);
    };

    useEffect(() => {
        const data = FilteredData();
        setFilterData(data);
    }, [siteData, selectedSizes]);

    return (
        <div className="design2">
            <div className="barChartPage2" style={{ backgroundImage: `url(${bgImage})` }}>
                <div className="filter">
                    <h1 style={{ marginLeft: "30px", fontSize: "40px" }}>Site Status Breakdown by Zone</h1>
                    <div className="divider"></div>
                    <div className="select-Size" style={{ marginRight: "50px", width: "200px" }}>
                        <FormControl
                            style={{ backgroundColor: "#2B3D6C", border: "3px solid black", color: "white" }}
                            required
                            sx={{ m: 1, minWidth: 160 }}
                        >
                            <Select
                                style={{ color: "white" }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                id="size-select"
                                value={selectedSizes}
                                onChange={handleSizeChange}
                                multiple
                                IconComponent={CustomArrowIcon}
                                renderValue={() => 'Size'}
                            >
                             
                                {sizes.map((size) => (
                                    <MenuItem key={size} value={size}>
                                        <Checkbox checked={selectedSizes.includes(size)} />
                                        <ListItemText primary={size} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                    </div>
                </div>

                <div className="breakdownData" style={{ marginLeft: "15px" }}>
                    <ResponsiveContainer width="100%" height={chartHeight}>
                        <BarChart data={filterData}>
                            <XAxis dataKey="zone" tick={{ fill: 'black', fontSize: "20px" }} />
                            <YAxis tick={{ fill: 'black', fontSize: "20px" }} />
                            <RechartsTooltip />
                            <RechartsLegend
                                wrapperStyle={{
                                    alignItems: "center",
                                    padding: "10px",
                                    marginTop: "400px",
                                    borderRadius: "40px",
                                    fontWeight: "600",
                                    color: "black"
                                }}
                            />
                            {orderedStatus.map(status => (
                                <Bar
                                    barGap={10}
                                    barSize={30}
                                    radius={[20, 20, 0, 0]}
                                    key={status}
                                    dataKey={status}
                                    fill={getFillColor(status)}
                                />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};


const getFillColor = (status) => {
    switch (status) {
        case 'new':
            return '#E2E2E2';
        case 'New-Assigned':
        case 'new-assigned':
        case 'ground-inspection':
        case 're-inspection':
            return '#F7D000';
        case 'pmay':
        case 'map-issued':
        case 'map-submitted':
        case 'govt-campus':
        case 'not-applicable':
            return 'green';
        case 'notice':
        case 'do-issued':
            return '#c76e00';
        case 'demolished':
        case 'sealed':
        case 'court-case':
            return 'red';
        default:
            return '#ccc'; // Default color for unknown statuses
    }
};

export default Page2;
