import { BarChart, Bar, XAxis, ResponsiveContainer, YAxis, Tooltip as RechartsTooltip, Legend as RechartsLegend } from 'recharts';
import { useEffect, useState } from "react";
import "../Analytics/Analytics.css";
import { MenuItem, Select, FormControl, Checkbox, ListItemText } from "@mui/material";
import bgImage from "../../assets/images/newDesign.jpg";
import { useSelector } from 'react-redux';
const Page3 = ({ sites, landtypes }) => {
    const [filterdata, setFilterData] = useState([]);
    const [selectedZones, setSelectedZones] = useState([]);
    const [chartHeight, setChartHeight] = useState(620); 

    const zones = useSelector((state) => state.zones);
  const propertyTypeData = useSelector(
    (state) => state.markerCoordinates.propertyType
  )
    const CustomArrowIcon = () => (
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10H7Z" fill="white" />
        </svg>
    );

    useEffect(() => {
        const zo = zones.data.map((sitesZone)=>sitesZone.Zone)
        setSelectedZones(zo);
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth <= 1300) {
                setChartHeight(490);
            } else if (screenWidth <= 1600) {
                setChartHeight(510);
            }
             else if (screenWidth <= 1900) {
                setChartHeight(510);
            }
             else {
                setChartHeight(700);
            }
        };

        handleResize(); 
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const Filteringdata = () => {
        const landTypeCounts = {};
        const landTypeSet = new Set(propertyTypeData);

        sites.forEach(site => {
            const landType = site['Land-Property Classification'];
            if (selectedZones.length === 0 || selectedZones.includes(site.Zone)) {
                if (landTypeSet.has(landType)) {
                    if (!landTypeCounts[landType]) {
                        landTypeCounts[landType] = 0;
                    }
                    landTypeCounts[landType]++;
                }
            }
        });

        const formattedData = Object.entries(landTypeCounts).map(([key, value]) => ({
            name: key,
            count: value
        }));

        setFilterData(formattedData);
    };

    const handleZoneChange = (event) => {
        setSelectedZones(event.target.value);
    };

    useEffect(() => {
        Filteringdata();
    }, [selectedZones, sites, landtypes]);

    return (
        <>
            <div className="chart3"
                style={{
                    alignItems: "center",
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                }}
            >
                <div className='filter'>
                    <h1 style={{ marginLeft: "30px" }}>SITES DETECTED WERE VARIED IN SIZE AND CLASSIFICATION</h1>
                    <div className="divider" style={{ marginRight: "80px", marginBottom: "40px" }}></div>
                    <div className="select-Size" style={{ marginRight: "80px" }}>
                        <FormControl required sx={{ m: 1, minWidth: 160 }}
                            style={{
                                backgroundColor: "#2B3D6C",
                                border: "3px solid black",
                                color: "white",
                                marginBottom: "40px"
                            }}
                        >
                            <Select
                                style={{ color: "white" }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                id="zone-select"
                                multiple
                                value={selectedZones}
                                onChange={handleZoneChange}
                                renderValue={() => 'Zone'}
                                IconComponent={CustomArrowIcon}
                            >
                       
                                {zones.data.map((zone) => (
                                    <MenuItem key={zone.Zone} value={zone.Zone}>
                                        <Checkbox checked={selectedZones.indexOf(zone.Zone) > -1} />
                                        <ListItemText primary={zone.Zone} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>

                {/* Bar Chart */}
                <div className="analysis" style={{ marginLeft: "40px", marginTop: "50px" }}>
                    <ResponsiveContainer width="100%" height={chartHeight}>
                        <BarChart
                            width={1200}
                            height={510}
                            margin={{ bottom: 90 }}
                            data={filterdata}
                            layout="horizontal"
                        >
                            <XAxis
                                dataKey="name"
                                type="category"
                                interval={0}
                                tick={{
                                    fill: 'black',
                                    fontWeight: 'bold',
                                    angle: -25,
                                    fontSize: "8px",
                                    textAnchor: 'end'
                                }}
                            />
                            <YAxis
                                type="number"
                                tick={{ fill: 'black', fontWeight: 'bold' }}
                            />
                            <RechartsTooltip />
                            <RechartsLegend wrapperStyle={{ color: 'black' }} />
                            <Bar
                                barSize={50}
                                dataKey="count"
                                fill="grey"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </>
    );
};

export default Page3;
