import React from "react";

import "./GeotricsFooter.css";
import logo from "../../assets/images/logo-2.png";

const GeotricsFooter = () => {
  return (
    <div className="geotrics-footer">
      <img
        src={logo}
        alt="logo"
      />
      Geotrics Analytics Pvt. Ltd.
    </div>
  );
};

export default GeotricsFooter;
