import React, { useState } from "react";

import "./Payment.css";
import ComingSoonBanner from "../Loader/ComingSoonBanner"
import payment_page from "../../assets/images/payment.png";

const Payment = () => {
  return (
    <div className="payment-box-container">
      <img src={payment_page} alt="" />
      <ComingSoonBanner />
    </div>
  );
};

export default Payment;
