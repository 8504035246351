import React, { useState, useEffect } from "react";
import { Icon, TextField, Checkbox, Box, Button } from "@mui/material";

import "./SidebarSiteProgress.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSiteStatus,
  setSidebarType,
  setSidebarVisibility,
  updateCurrentGroundInspectionImages,
  updateSiteInfoAttribute,
} from "../../redux/reducers/selectedSitesSidebar";
import {
  updateSiteInfoAttributeInAllSites,
  updateSiteStatus,
} from "../../redux/reducers/mapMarkers";
import Translate from "../Translate/Translate";
import DottedCircleLoader from "../Loader/DottedCircleLoader";
import { Check } from "@mui/icons-material";
import { setStatusDropdownVisibility } from "../../redux/reducers/siteStatus";
import { useAuth0 } from "@auth0/auth0-react";
import helperFunctions from "../../utils/helper";

import pmayLogo from "../../assets/images/pmay-logo.png";
import toast from "react-hot-toast";
import { setApplicationField } from "../../redux/reducers/application";

function SidebarSiteProgress(props) {
  const verticalSidebar = useSelector((state) => state.verticalSidebar);
  const siteInfo = useSelector(
    (state) => state.verticalSidebar.currentSelectedSite
  );
  const statusConfig = useSelector((state) => state.siteStatus.data);
  const statusConfigMap = useSelector((state) => state.siteStatus.dataMap);
  const statusDropdownVisibility = useSelector(
    (state) => state.siteStatus.statusDropdownVisibility
  );
  const uploadedImage = useSelector((state) => state.application.uploadedImage);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [editActiveContent, setEditActiveContent] = useState("");

  const { user, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const updateSiteStatusAPI = async (siteID, newStatus) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });
    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/update_site_status?site_id=${siteID}&new_status=${newStatus}`;
    const authToken = accessToken; // Replace with your actual authorization token

    return new Promise((resolve, reject) => {
      fetch(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Process the retrieved data here
          resolve(data);
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error("Error: Something went wrong");
          reject(error);
        });
    });
  };

  const handleSiteStatusChange = (status) => {
    console.log(status);
    toast.promise(
      updateSiteStatusAPI(siteInfo.ID, status)
        .then((data) => {
          console.log(status);
          
          dispatch(changeSiteStatus(status));
          dispatch(updateSiteStatus({ status: status, ID: siteInfo.ID }));
          dispatch(setStatusDropdownVisibility(false));
        })
        .catch((error) => {
          console.log(error);
          console.error("Error in site status change");
        }),
      {
        loading: `Updating site status to ${statusConfigMap[status].label}`,
        success: `Site status updated to ${statusConfigMap[status].label}`,
        error: `Error in updating site status`,
      },
      {
        style: {
          minWidth: "fit-content",
          width: "auto",
        },
      }
    );
  };

  const handleEditAddress = () => {
    // return;
    setShowEditAddress(true);
  };

  const dispatch = useDispatch();

  const editAddressConfig = [
    {
      label: "Address Line 1",
      type: "addressLine1",
      width: "100%",
    },
    {
      label: "Address Line 2",
      type: "addressLine2",
      width: "100%",
    },
    {
      label: "District/City",
      type: "district",
      width: "100%",
    },
    {
      label: "State",
      type: "state",
      width: "50%",
    },
    {
      label: "Pincode",
      type: "pincode",
      width: "50%",
    },
  ];

  const [formData, setFormData] = useState({});

  const handleEditAddressInput = (inputType, inputValue) => {
    const obj = {};
    obj[inputType] = inputValue;
    setFormData({
      ...formData,
      ...obj,
    });
  };

  const createAddressString = () => {
    let addressString = "";

    if (formData.addressLine1 && formData.addressLine1 !== "") {
      addressString += formData.addressLine1;
    }
    if (formData.addressLine2 && formData.addressLine2 !== "") {
      addressString += formData.addressLine2;
    }
    if (formData.district && formData.district !== "") {
      addressString += formData.district;
    }
    if (formData.state && formData.state !== "") {
      addressString += formData.state;
    }
    if (formData.pincode && formData.pincode !== "") {
      addressString += formData.pincode;
    }

    return addressString;
  };

  const updateSiteAddressAPI = async (siteID, callback) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    let addressString = createAddressString();

    addressString = await helperFunctions.translateText(
      addressString,
      "en",
      user.language
    );

    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/put_site_address?site_id=${siteID}&address=${addressString}`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
      });
  };

  const submitForm = () => {
    updateSiteAddressAPI(siteInfo.ID, (data) => {
      setShowEditAddress(false);
    });
  };

  const selectedMarker = useSelector(
    (state) => state.verticalSidebar.selectedSites
  );

  function countTrueValues(obj) {
    return Object.values(obj).reduce(
      (count, value) => count + (value === true ? 1 : 0),
      0
    );
  }

  const handleCloseSiteProgressSidebar = () => {
    const trueCount = countTrueValues(selectedMarker);

    if (trueCount > 0) {
      dispatch(setSidebarType("pathTraversal"));
    } else {
      dispatch(setSidebarVisibility(false));
    }
  };

  function parseDateTime(dateTimeString) {
    try {
      const [datePart, timePart] = dateTimeString.split(" ");
      const [day, month, year] = datePart.split(".");

      let [hours, minutes, seconds] = timePart.split(":");

      if (hours.length === 1) {
        hours = "0" + hours;
      }

      if (minutes.length === 1) {
        minutes = "0" + minutes;
      }

      if (seconds.length === 1) {
        seconds = "0" + seconds;
      }

      // Note: Month in Date constructor is zero-based, so subtract 1 from the parsed month
      const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

      return formattedDateTime;
    } catch (e) {
      console.error("Error: Something went wrong");
      return dateTimeString;
    }
  }

  // usestate for site ground inspection images
  const siteGroundInspectionImages = useSelector(
    (state) => state.verticalSidebar.currentGroundInspectionImages
  );
  console.log("siteGroundInspectionImages ", siteGroundInspectionImages)
  // const [siteGroundInspectionImages, setSiteGroundInspectionImages] = useState([]);
  const [
    isSiteGroundInspectionImagesLoading,
    setIsSiteGroundInspectionImagesLoading,
  ] = useState(false);

  const [showAllImages, setShowAllImages] = useState(false);
  const [updateSiteImagesFolder, setUpdateSiteImagesFolder] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const getGroundInspectionImageFromLocal = (siteID) => {
    let siteInspectionImages =
      localStorage.getItem("siteInspectionImages") === null
        ? {}
        : JSON.parse(localStorage.getItem("siteInspectionImages"));

    if (siteInspectionImages[siteID] === undefined) {
      return;
    }

    if (siteInspectionImages[siteID].length === 0) {
      return;
    }

    dispatch(
      updateCurrentGroundInspectionImages({
        images: siteInspectionImages[siteID],
        siteID: siteID,
      })
    );
    // setSiteGroundInspectionImages(siteInspectionImages[siteID]);

    setCurrentImageIndex(0);
  };

  const setGroundInspectionImageToLocal = (siteID, data) => {
    let siteInspectionImages =
      localStorage.getItem("siteInspectionImages") === null
        ? {}
        : JSON.parse(localStorage.getItem("siteInspectionImages"));

    if (siteInspectionImages[siteID] === undefined) {
      siteInspectionImages[siteID] = [];
    }

    siteInspectionImages[siteID] = data;

    localStorage.setItem(
      "siteInspectionImages",
      JSON.stringify(siteInspectionImages)
    );
  };

  const getGroundInspectionImages = async (siteID) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    return new Promise((resolve, reject) => {
      if (siteID === undefined) {
        reject("Site ID is undefined");
        return;
      }

      if (isSiteGroundInspectionImagesLoading) {
        reject("Site ground inspection images are already loading");
        return;
      }

      getGroundInspectionImageFromLocal(siteID);

      const url =
        process.env.REACT_APP_API_BASE_URL +
        `/get_ground_inspection_images?site_id=${siteID}`;
      const authToken = accessToken; // Replace with your actual authorization token

      setIsSiteGroundInspectionImagesLoading(true);
      fetch(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          setIsSiteGroundInspectionImagesLoading(false);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Process the retrieved data here

          if (data === null) {
            dispatch(
              updateCurrentGroundInspectionImages({
                images: [],
                siteID: siteID,
              })
            );
            // setSiteGroundInspectionImages([]);
            throw new Error("No data received from server");
          }

          if (data.length === 0) {
            dispatch(
              updateCurrentGroundInspectionImages({
                images: [],
                siteID: siteID,
              })
            );
            // setSiteGroundInspectionImages([]);
            throw new Error("Site has no ground inspection images");
          }

          if (Object.keys(data).length === 0) {
            dispatch(
              updateCurrentGroundInspectionImages({
                images: [],
                siteID: siteID,
              })
            );
            // setSiteGroundInspectionImages([]);
            throw new Error("No ground inspection images found");
          }

          // iterate over data object
          const __siteImages = [];
          // convert above data to array of object with base64, date, coordinates, coordinates from site coordinates
          for (let i = 0; i < Object.keys(data[0]).length; i++) {
            // console.log(data["0"][i]);
            // console.log(data["0"][i.toString()]);
            const obj = {
              base64: data[0][i],
              imgDate: data[1][i],
              coordinates: data[2][i],
              distance: data[3][i],
              s3Path: data[4][i],
            };
            __siteImages.push(obj);
          }

          // console.log(
          //   "__siteImages ====== ",
          //   __siteImages,
          //   __siteImages.length
          // );

          dispatch(
            updateCurrentGroundInspectionImages({
              images: __siteImages,
              siteID: siteID,
            })
          );
          // setSiteGroundInspectionImages(__siteImages);
          setCurrentImageIndex(0);

          setGroundInspectionImageToLocal(siteID, __siteImages);
          resolve(__siteImages);
        })
        .catch((error) => {
          setIsSiteGroundInspectionImagesLoading(false);
          // Handle any errors that occurred during the request
          console.error("Error: Something went wrong");
          reject(`Error getting ground inspection images: ${error}`);
        });
    });
  };

  const setImagePage = (move) => {
    if (
      move === "next" &&
      currentImageIndex < siteGroundInspectionImages.length - 1
    ) {
      setCurrentImageIndex(currentImageIndex + 1);
    } else if (move === "prev" && currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const maximizeImage = (index) => {
    setCurrentImageIndex(index);
    setShowAllImages(true);
  };

  const application = useSelector((state) => state.application);

  const showImageBox = () => {
    dispatch(setApplicationField({ showImageBox: !application.showImageBox }));
  };

  const transformDistance = (meters) => {
    if (typeof meters !== "number" || isNaN(meters)) {
      console.error("Input must be a number");
      return "-- NAN --";
    }

    if (meters >= 1000) {
      const kilometers = meters / 1000;
      return kilometers % 1 === 0
        ? kilometers.toFixed(0) + " km"
        : kilometers.toFixed(2) + " km";
    } else {
      return meters + " m";
    }
  };

  // const deleteGroundInspectionImage = async (s3Path) => {
  //   console.log("S3 path is", s3Path)
  //   const accessToken = await getAccessTokenSilently({
  //     authorizationParams: {
  //       audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  //     },
  //   });

  //   const url =
  //     process.env.REACT_APP_API_BASE_URL +
  //     `/delete_image_from_s3?s3_path=${s3Path}`;
  //   const authToken = accessToken; // Replace with your actual authorization token

  //   console.log(url);
  //   fetch(url, {
  //     method: 'DELETE',
  //     headers: {
  //       Authorization: `Bearer ${authToken}`,
  //     },
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       // Process the retrieved data here
  //       console.log(data);
  //     })
  //     .catch((error) => {
  //       // Handle any errors that occurred during the request
  //       console.error("Error:", error);
  //     });
  // };

const deleteGroundInspectionImage = async (s3Path) => {
  try {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const url = `${process.env.REACT_APP_API_BASE_URL}/delete_image_from_s3?s3_path=${s3Path}`;
    const authToken = accessToken; // Replace with your actual authorization token

    // Wrap the fetch call in a promise
    await toast.promise(
      fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Process the retrieved data here
          console.log("Data is retrieved");
        }),
      {
        loading: "Deleting image...",
        success: "Image deleted successfully",
        error: "Failed to delete image",
      },
      {
        style: {
          minWidth: "fit-content",
          width: "auto",
        },
      }
    );
  } catch (error) {
    // Handle any errors that occurred during the request
    console.error("Error: Something went wrong");
  }
  setShowAllImages(!showAllImages)
  setUpdateSiteImagesFolder(!updateSiteImagesFolder)
};

  // useEffect to get ground inspection images
  useEffect(() => {
    if (siteInfo.ID) {
      dispatch(
        updateCurrentGroundInspectionImages({ images: [], siteID: siteInfo.ID })
      );
      // setSiteGroundInspectionImages([]);
      setIsSiteGroundInspectionImagesLoading(true);
      toast.promise(
        getGroundInspectionImages(siteInfo.ID)
          .then((data) => {
            setIsSiteGroundInspectionImagesLoading(false);
          })
          .catch((error) => {
            setIsSiteGroundInspectionImagesLoading(false);
            console.error("Error in getting ground inspection images");
          }),
        {
          loading: "Loading ground inspection images...",
          success: "Ground inspection images loaded successfully",
          error: "Error loading ground inspection images",
        },
        {
          style: {
            minWidth: "fit-content",
            width: "auto",
          },
        }
      );
      // getGroundInspectionImages(siteInfo.ID);
    }
    if(updateSiteImagesFolder){
      setUpdateSiteImagesFolder(!updateSiteImagesFolder)
    }
  }, [siteInfo.ID,updateSiteImagesFolder,uploadedImage]);

  return (
    verticalSidebar.showSidebar && (
      <div className="site-info-sidebar-container">
        <div className="site-info-sidebar-header">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <h4>
              <Translate
                text={
                  verticalSidebar.sidebarType === "siteProgress"
                    ? "Site Progress"
                    : "Site Info"
                }
              />
            </h4>

            <div
              onClick={() => {
                // dispatch(setSidebarVisibility(false));
                // dispatch(setSidebarType("pathTraversal"));
                handleCloseSiteProgressSidebar();
              }}
            >
              <Icon
                baseClassName="fas"
                className={"fa-solid fa-xmark cursor-pointer"}
                fontSize="small"
              />
            </div>
          </div>

          <div className="siteid-container">
            <p>
              <Translate text={"ID"} targetLanguage="en" />
              {": "}
              <Translate text={siteInfo.ID} />
            </p>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Icon
                baseClassName="fas"
                className={"fa-solid fa-box cursor-pointer"}
                fontSize="small"
              />
              <Translate text={"Delivery Number : "} />{" "}
              {siteInfo["Delivery Number"]}
            </div>

            <div onClick={() => {}}>
              <a href={siteInfo.maplink} target="_blank">
                <Icon
                  baseClassName="fas"
                  className={"fa-map-location-dot cursor-pointer"}
                  fontSize="small"
                />
              </a>
            </div>

            <div onClick={() => {}}>
              <a href={siteInfo.sv_Link} target="_blank">
                <Icon
                  baseClassName="fas"
                  className={"fa-street-view cursor-pointer"}
                  fontSize="small"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="site-info-status-select-container">
          <h4 className="m-0">
            <Translate text={"Site Status"} />
          </h4>

          <div
            onBlur={() => dispatch(setStatusDropdownVisibility(false))}
            tabIndex={0}
          >
            <div
              className="site-info-status-select-sub-container cursor-pointer"
              onClick={() =>
                dispatch(setStatusDropdownVisibility(!statusDropdownVisibility))
              }
              style={{
                color: statusConfigMap[siteInfo.status].color,
                background: "var(--bg-color)",
                borderRadius: "0.5rem",
                backgroundColor: "grey",
              }}
            >
              {statusConfigMap[siteInfo.status].type === "image" ? (
                <img
                  src={pmayLogo}
                  alt={statusConfigMap[siteInfo.status].label}
                  style={{
                    width: "3rem",
                    height: "1.5rem",
                  }}
                />
              ) : (
                <Icon
                  baseClassName={
                    statusConfigMap[siteInfo.status].iconBaseClass || "fas"
                  }
                  className={
                    "cursor-pointer " + statusConfigMap[siteInfo.status].icon
                  }
                  fontSize="small"
                />
              )}

              <p className="m-0" style={{ flexGrow: 1 }}>
                <Translate text={statusConfigMap[siteInfo.status].label} />
              </p>

              <Icon
                baseClassName={
                  statusConfigMap[siteInfo.status].iconBaseClass || "fas"
                }
                className={"cursor-pointer fa-caret-down"}
                fontSize="small"
              />
            </div>

            <div
              className={
                "status-select-dropdown" +
                (statusDropdownVisibility ? "" : " hide")
              }
            >
              {statusConfig.map((statusObj) => {
                return (
                  <div
                    className="site-info-status-select-sub-container cursor-pointer"
                    style={{ color: statusObj.color }}
                    onClick={() => {
                    handleSiteStatusChange(statusObj.value);
                    }}
                  >
                    {statusObj.type === "image" ? (
                      <img
                        src={pmayLogo}
                        alt={statusObj.label}
                        style={{
                          width: "3rem",
                          height: "1.5rem",
                        }}
                      />
                    ) : (
                      <Icon
                        baseClassName={statusObj.iconBaseClass || "fas"}
                        className={"cursor-pointer " + statusObj.icon}
                        fontSize="small"
                      />
                    )}

                    <p className="m-0" style={{ flexGrow: 1 }}>
                      <Translate text={statusObj.label} />
                    </p>

                    {siteInfo.status === statusObj.value && <Check />}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="site-info-details-container">
          {siteGroundInspectionImages && (
            <div className="site-info-details-sub-container">
              <h4 className="m-0">
                <Translate text={"SITE IMAGES"} />

                <div className="row m-0 gap-1">
                  <div className="badge" onClick={showImageBox}>
                    <Icon
                      baseClassName={"fas"}
                      className={"cursor-pointer fa-plus"}
                      fontSize="small"
                      style={{ marginRight: "0.1rem", fontSize: "1rem" }}
                    />
                    <Translate text={"Add Image"} />
                  </div>

                  {siteGroundInspectionImages &&
                    siteGroundInspectionImages.length > 0 && (
                      <div
                        className="badge"
                        onClick={() => {
                          setShowAllImages(!showAllImages);
                        }}
                      >
                        <Translate text={"View All"} />
                        <Icon
                          baseClassName={"fas"}
                          className={"cursor-pointer fa-expand"}
                          fontSize="small"
                          style={{ marginLeft: "0.1rem", fontSize: "0.8rem" }}
                        />
                      </div>
                    )}
                </div>
              </h4>

              {isSiteGroundInspectionImagesLoading && (
                <p>
                  <Translate
                    text={
                      siteGroundInspectionImages &&
                      siteGroundInspectionImages.length === 0
                        ? "Loading ground inspection images..."
                        : "You might be seeing old images. Loading new ..."
                    }
                  />
                </p>
              )}

              {!isSiteGroundInspectionImagesLoading &&
                siteGroundInspectionImages &&
                siteGroundInspectionImages.length === 0 && (
                  <p>
                    <Translate text={"No ground inspection images found"} />
                  </p>
                )}

              <div className="site-info-ground-inspection-images-sub-container">
                {siteGroundInspectionImages.map((image, index) => {
                  return (
                    <div
                      onClick={() => maximizeImage(index)}
                      className="site-info-ground-inspection-image-container cursor-pointer"
                    >
                      <img
                        src={"data:image/png;base64, " + image.base64}
                        alt="ground-inspection-image"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <div className="site-info-details-sub-container">
            <div
              style={{ display: "flex", gap: "0.3rem", alignItems: "center" }}
            >
              <h5 className="m-0">
                <Translate text={"SITE ADDRESS"} />
              </h5>
              <div onClick={() => handleEditAddress()}>
                <Icon
                  baseClassName={"fas"}
                  className={"cursor-pointer fa-pen"}
                  fontSize="small"
                />
              </div>
            </div>
            <p>
              <Translate text={siteInfo.Address || "No Address Available"} />
            </p>
          </div>

          <div className="site-info-details-sub-container">
            <div
              style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}
            >
              <h5 className="m-0">
                <Translate text={"ID"} targetLanguage="en" />
              </h5>
            </div>
            <p>
              <Translate text={siteInfo.ID} />
            </p>
          </div>

          <div className="site-info-details-sub-container">
            <div
              style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}
            >
              <h5 className="m-0">
                <Translate text={"ZONE"} />
              </h5>
            </div>
            <p>
              <Translate text={siteInfo.Zone} />
            </p>
          </div>

          <div className="site-info-details-sub-container">
            <div
              style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}
            >
              <h5 className="m-0">
                <Translate text={"SIZE"} />
              </h5>
            </div>
            <p>
              <Translate text={siteInfo.size} />
            </p>
          </div>

          <div className="site-info-details-sub-container">
            <div
              style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}
            >
              <h5 className="m-0">
                <Translate text={"PROPERTY TYPE"} />
              </h5>
            </div>
            <p>
              <Translate text={siteInfo["Land-Property Classification"]} />
            </p>
          </div>

          <div className="site-info-details-sub-container">
            <div
              style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}
            >
              <h5 className="m-0">
                {" "}
                <Translate text={"ASSIGNED EMPLOYEE"} />
              </h5>
            </div>
            <p>
              <Translate text={siteInfo["Assigned Employee"]} />
            </p>
          </div>

          <div className="site-info-details-sub-container">
            <div
              style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}
            >
              <h5 className="m-0">
                {" "}
                <Translate text={"LONGITUDE"} />
              </h5>
            </div>
            <p>
              <Translate text={siteInfo.Longitude} />
            </p>
          </div>

          <div className="site-info-details-sub-container">
            <div
              style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}
            >
              <h5 className="m-0">
                <Translate text={"LATITUDE"} />
              </h5>
            </div>
            <p>
              <Translate text={siteInfo.Latitude} />
            </p>
          </div>

          <div className="site-info-details-sub-container">
            <div
              style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}
            >
              <h5 className="m-0">
                <Translate text={"REGION"} />
              </h5>
            </div>
            <p>
              <Translate text={siteInfo.Region} />
            </p>
          </div>

          <div className="site-info-details-sub-container">
            <div
              style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}
            >
              <h5 className="m-0">
                <Translate text={"PLOTTING"} />
              </h5>
            </div>
            <p>
              <Translate text={siteInfo.plotting} />
            </p>
          </div>

          <SiteInfoDetailsSubContainer
            label={"OWNER NAME"}
            value={siteInfo.owner_name}
            siteInfo={siteInfo}
            labelKey={"owner_name"}
            showContentEditableButton={true}
            currentActiveEditContent={editActiveContent}
            setEditActiveContent={setEditActiveContent}
          />

          <SiteInfoDetailsSubContainer
            label={"NOTICE DATE"}
            value={siteInfo.notice_date}
            siteInfo={siteInfo}
            labelKey={"notice_date"}
            showContentEditableButton={true}
            currentActiveEditContent={editActiveContent}
            setEditActiveContent={setEditActiveContent}
          />

          <SiteInfoDetailsSubContainer
            label={"REMARKS"}
            value={siteInfo.remarks}
            siteInfo={siteInfo}
            labelKey={"remarks"}
            showContentEditableButton={true}
            currentActiveEditContent={editActiveContent}
            setEditActiveContent={setEditActiveContent}
          />

          <SiteInfoDetailsSubContainer
            label={"GROUND INSPECTION SIZE"}
            value={siteInfo.ground_inspection_size}
            siteInfo={siteInfo}
            labelKey={"ground_inspection_size"}
            showContentEditableButton={true}
            currentActiveEditContent={editActiveContent}
            setEditActiveContent={setEditActiveContent}
          />
        </div>

        {showEditAddress && (
          <div className="edit-address-modal-container">
            <div className="edit-address-modal">
              <div className="edit-address-modal-header">
                <h3>Enter Address</h3>

                <div
                  onClick={() => setShowEditAddress(false)}
                  className="close-modal"
                >
                  <Icon
                    baseClassName={"fas"}
                    className={"cursor-pointer fa-xmark"}
                    fontSize="small"
                  />
                </div>
              </div>
              <div className="edit-address-form">
                {editAddressConfig.map((config) => {
                  if (config.width === "100%") {
                    return (
                      <div className="edit-address-form-cell-container">
                        <label htmlFor={config.type}>{config.label}</label>

                        <TextField
                          type="text"
                          className="edit-address-input"
                          name={config.type}
                          id={config.type}
                          fullWidth
                          focused
                          value={
                            formData[config.type] !== null
                              ? formData[config.type]
                              : ""
                          }
                          onInput={(evt) =>
                            handleEditAddressInput(
                              config.type,
                              evt.target.value
                            )
                          }
                        />
                      </div>
                    );
                  }
                })}
              </div>

              <div className="edit-address-state-pincode-container">
                {editAddressConfig.map((config) => {
                  if (config.width === "50%") {
                    return (
                      <div className="edit-address-form-cell-container">
                        <label htmlFor={config.type}>{config.label}</label>

                        <TextField
                          type="text"
                          className="edit-address-input"
                          name={config.type}
                          id={config.type}
                          fullWidth
                          focused
                          onInput={(evt) =>
                            handleEditAddressInput(
                              config.type,
                              evt.target.value
                            )
                          }
                        />
                      </div>
                    );
                  }
                })}
              </div>

              <div className="save-address-button">
                <button onClick={() => submitForm()}>Save Address</button>
              </div>
            </div>
          </div>
        )}

        {showAllImages &&
          siteGroundInspectionImages &&
          siteGroundInspectionImages.length > 0 && (
            <div className="modal-container">
              <div className="modal-container-box">
                <div className="topbar row m-0">
                  <h3 className="m-0">
                    <Translate text={"SITE IMAGES"} />
                  </h3>
                  <div
                    className="close-modal"
                    onClick={() => setShowAllImages(!showAllImages)}
                  >
                    <Icon
                      baseClassName="fas"
                      className={"fa-solid fa-xmark cursor-pointer"}
                      fontSize="medium"
                    />
                  </div>
                </div>

                {currentImageIndex < siteGroundInspectionImages.length && (
                  <div>
                    <div className="image-box-meta-container row m-0">
                      <div className="metadata-container row m-0">
                        <Icon
                          baseClassName="fas"
                          className={"fa-solid fa-calendar"}
                          fontSize="medium"
                        />

                        <div className="metadata-value">
                          {parseDateTime(
                            siteGroundInspectionImages[currentImageIndex]
                              .imgDate
                          )}
                        </div>
                      </div>

                      <div className="metadata-container row m-0">
                        <Icon
                          baseClassName="fas"
                          className={"fa-solid fa-location-pin"}
                          fontSize="medium"
                        />

                        <div className="metadata-value">
                          {
                            siteGroundInspectionImages[currentImageIndex]
                              .coordinates
                          }
                        </div>
                      </div>

                      <div className="metadata-container row m-0">
                        <Icon
                          baseClassName="fas"
                          className={"fa-solid fa-ruler"}
                          fontSize="medium"
                        />

                        <div className="metadata-value">
                          {transformDistance(
                            siteGroundInspectionImages[currentImageIndex]
                              .distance
                          )}
                        </div>
                      </div>

                      <div className="metadata-container row m-0"
                        onClick={() => deleteGroundInspectionImage(siteGroundInspectionImages[currentImageIndex].s3Path)}
                      >
                        <Icon
                          baseClassName="fas"
                          className={"fa-solid fa-trash cursor-pointer"}
                          fontSize="medium"
                          style={{ color: 'red' }}
                        />
                      </div>

                    </div>

                    <div className="image-box-container-with-controls row m-0">
                      <div className="control-btn">
                        <div
                          className={currentImageIndex === 0 ? "disabled" : ""}
                          onClick={() => {
                            setImagePage("prev");
                          }}
                        >
                          <Icon
                            baseClassName="fas"
                            className={
                              "fa-solid fa-chevron-left cursor-pointer"
                            }
                            fontSize="medium"
                          />
                        </div>
                      </div>

                      <div className="site-info-ground-inspection-image-container">
                        <div className="img-div">
                          <img
                            src={
                              "data:image/png;base64, " +
                              siteGroundInspectionImages[currentImageIndex]
                                .base64
                            }
                            alt="ground-inspection-image"
                          />
                        </div>

                        <div className="img-pagination row">
                          {siteGroundInspectionImages.map((image, index) => {
                            return (
                              <div
                                onClick={() => {
                                  setCurrentImageIndex(index);
                                }}
                                className={
                                  "pagination-circles cursor-pointer" +
                                  (index === currentImageIndex ? " fill" : "")
                                }
                              ></div>
                            );
                          })}
                        </div>
                      </div>

                      <div className={"control-btn"}>
                        <div
                          className={
                            currentImageIndex ===
                            siteGroundInspectionImages.length - 1
                              ? "disabled"
                              : ""
                          }
                          onClick={() => {
                            setImagePage("next");
                          }}
                        >
                          <Icon
                            baseClassName="fas"
                            className={
                              "fa-solid fa-chevron-right cursor-pointer"
                            }
                            fontSize="medium"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    )
  );
}

function SiteInfoDetailsSubContainer({
  label,
  value,
  showContentEditableButton,
  currentActiveEditContent,
  setEditActiveContent,
  siteInfo,
  labelKey,
}) {
  const [textValue, setTextValue] = useState(value);
  const [editValue, setEditValue] = useState(value);
  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch();

  const callUpdateAPI = async () => {
  //  console.log("callUpdateAPI called with ", editValue);

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const attributeMap = {
      owner_name: "owner_name",
      notice_date: "notice_date",
      remarks: "remarks",
      ground_inspection_size: "ground_inspection_size",
    };

    setTextValue(editValue);

    const callback = (data, err) => {
      if (err) {
        setTextValue(value);
        setEditValue(value);
        console.error("Error updating site attribute");
        return;
      }

    //  console.log("Updated API response ", data);
      dispatch(
        updateSiteInfoAttribute({ attribute: labelKey, value: editValue })
      );
      dispatch(
        updateSiteInfoAttributeInAllSites({
          attribute: labelKey,
          value: editValue,
          ID: siteInfo.ID,
        })
      );
      setTextValue(editValue);
  //    console.log("Text Value updated to ", editValue);
    };

    if (editValue === textValue) {
      toast.error(
        "No changes made in input field.\nPlease make changes to save."
      );
      setTextValue(value);
      setEditValue(value);
      return;
    }

    toast.promise(
      updateSiteAttributesAPI(
        siteInfo.ID,
        labelKey,
        attributeMap[labelKey] || labelKey,
        editValue,
        accessToken,
        user
      ).then(callback),
      {
        loading: "Updating site attribute...",
        success: "Site attribute updated successfully",
        error: "Error updating site attribute",
      }
    );
  };

  const saveValueEdit = (evt) => {
  //  console.log(`saveValueEdit called with ${editValue}`);
    callUpdateAPI();
  };

  return (
    <div className="site-info-details-sub-container">
      <div style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}>
        <h5 className="m-0">
          <Translate text={label} />
        </h5>

        {showContentEditableButton && (
          <>
            {currentActiveEditContent === label ? (
              <div
                onClick={() => {
                  saveValueEdit();
                  setEditActiveContent(
                    currentActiveEditContent === label ? "" : label
                  );
                }}
              >
                <Icon
                  baseClassName={"fas"}
                  className={"cursor-pointer fa-save"}
                  fontSize="small"
                />
                <Translate text={"Save here"} />
              </div>
            ) : (
              <div
                onClick={() =>
                  setEditActiveContent(
                    currentActiveEditContent === label ? "" : label
                  )
                }
              >
                <Icon
                  baseClassName={"fas"}
                  className={"cursor-pointer fa-pen"}
                  fontSize="small"
                />
              </div>
            )}
          </>
        )}
      </div>
      <p>
        <Translate
          text={value}
          onTextChange={setEditValue}
          contentEditable={currentActiveEditContent === label}
          defaultValue={"-- Not Available --"}
        />
      </p>
    </div>
  );
}

const updateSiteAttributesAPI = (
  siteID,
  attributeName,
  attributeQueryParam,
  attributeValue,
  accessToken,
  user
) => {
  return new Promise(async (resolve, reject) => {
    // Input Validation
    if (
      !siteID ||
      !attributeName ||
      !attributeQueryParam ||
      attributeValue === null ||
      attributeValue === "" ||
      !accessToken ||
      !user
    ) {
      const errorMessage = "Invalid input parameters";
      console.error(errorMessage);
      reject(errorMessage);
      return;
    }

    // Translate attributeValue
    let paramValue;
    try {
      paramValue = await helperFunctions.translateText(
        attributeValue,
        "en",
        user.language
      );
    } catch (error) {
      console.error("Error in translating attribute value");
      reject(`Error translating attribute value: ${error}`);
      return;
    }

    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/put_${attributeName}?site_id=${siteID}&${attributeQueryParam}=${paramValue}`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response) {
          const errorMessage = "No response from server";
          console.error(errorMessage);
          throw new Error(errorMessage);
        }

        if (!response.ok) {
          const errorMessage = "Network response was not ok";
          console.error(errorMessage);
          throw new Error(errorMessage);
        }

        return response.json();
      })
      .then((data) => {
        // Process the retrieved data here
        resolve(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error in updating site attribute");
        reject(`Error updating site attribute: ${error}`);
      });
  });
};

export default SidebarSiteProgress;
