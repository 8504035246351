import { combineReducers } from '@reduxjs/toolkit';

import searchSiteidReducer from './searchSiteid';
import mapMarkersReducer from './mapMarkers';
import selectedMarkerReducer from './selectedMarker';
import zonesReducer from './zones';
import siteStatusReducer from './siteStatus';
import verticalSidebarReducer from './selectedSitesSidebar';
import verticalSidebarV2Reducer from './verticalSidebarV2';
import userReducer from './user';
import pathsReducer from './paths';
import fieldMembersReducer from './fieldMembers';
import applicationReducer from './application';
import clustersReducer from './clusters';
import mapViewReducer from './MapView';

const rootReducer = combineReducers({
    searchSiteid: searchSiteidReducer,
    markerCoordinates: mapMarkersReducer,
    selectedMarker: selectedMarkerReducer,
    zones: zonesReducer,
    siteStatus: siteStatusReducer,
    verticalSidebar: verticalSidebarReducer,
    verticalSidebarV2: verticalSidebarV2Reducer,
    user: userReducer,
    paths: pathsReducer,
    fieldMembers: fieldMembersReducer,
    application: applicationReducer,
    clusterCoordinates: clustersReducer,
    mapViews: mapViewReducer
});

export default rootReducer;
