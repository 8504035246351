import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  showSidebar: false,
  sidebarLevel: null,
  sidebarType: null,
  sheetLink: null,
  currentSelectedSite: null,
  siteImage: null,
  loading: false,
  error: null,
};

const verticalSidebarV2 = createSlice({
  name: "verticalSidebarV2",
  initialState,
  reducers: {
    setSidebarVisibility(state, action) {
      state.showSidebar = action.payload === true;
    },
    resetVerticalSidebar(state, action) {
      state.data = {};
      state.showSidebar = false;
      state.sidebarLevel = null;
      state.sidebarType = null;
      state.currentSelectedSite = null;
      state.siteImage = null;
      state.loading = false;
      state.error = null;
      state.sheetLink = null;
    },
    manipulateVerticalSidebar(state, action) {
      for (let key in action.payload) {
        state[key] = action.payload[key];
      }
    },
  },
});

export const {
  setSidebarVisibility,
  manipulateVerticalSidebar,
  resetVerticalSidebar,
} = verticalSidebarV2.actions;
export default verticalSidebarV2.reducer;
