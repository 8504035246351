import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@mui/material";

import "./MarkerPopup.css";

import {
  setSelectedMarkers,
  setSidebarType,
  setSidebarVisibility,
} from "../../redux/reducers/selectedSitesSidebar";
import { setStatusDropdownVisibility } from "../../redux/reducers/siteStatus";

import DottedCircleLoader from "../Loader/DottedCircleLoader";
import Translate from "../Translate/Translate";

import pmayLogo from "../../assets/images/pmay-logo.png";

const MarkerPopup = ({ popupCoordinates, siteImage, setShowPopup }) => {
  const selectedMarker = useSelector(
    (state) => state.verticalSidebar.selectedSites
  );
  const siteStatus = useSelector((state) => state.siteStatus);

  const dispatch = useDispatch();

  const handleCheckboxChange = () => {
    const currSiteId = popupCoordinates.ID;
    console.log("handleCheckboxChange ",popupCoordinates.Zone)
    // const currentCheckboxChange = {};
    // currentCheckboxChange[currSiteId] = true;

    // if (selectedMarker[popupCoordinates.ID]) {
    //   currentCheckboxChange[currSiteId] = false;
    // }

    dispatch(setSelectedMarkers(currSiteId));
    // dispatch(fetchSelectedMarkerSuccess(currentCheckboxChange));
    // dispatch(setSidebarType("pathTraversal"));
  };

  const handleSidebarTypeChange = (siteInfo, statusDropdownVisibility) => {
    dispatch(setSidebarVisibility(true));

    if (setStatusDropdownVisibility !== null) {
      dispatch(setStatusDropdownVisibility(statusDropdownVisibility));
    }

    dispatch(
      setSidebarType({
        sidebarType: siteInfo.status !== "new" ? "siteProgress" : "siteInfo",
        siteInfo: siteInfo,
      })
    );
  };

  return (
    <div className="marker-popup-container">
      <div className="select-path-traversal-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
          }}
        >
          {popupCoordinates.status === "new" && (
            <label
              htmlFor="select-path-traversal"
              className="select-path-traversal-label cursor-pointer"
            >
              <input
                type="checkbox"
                name="select-path-traversal"
                id="select-path-traversal"
                className="select-path-traversal"
                checked={selectedMarker && selectedMarker[popupCoordinates.ID]}
                onChange={handleCheckboxChange}
              />
              <Translate text="Select for Route" />
            </label>
          )}

          {popupCoordinates.status !== "new" && (
            <div className="marker-popup-top-status">
              {siteStatus.dataMap[popupCoordinates.status].type === "image" ? (
                <img
                  src={pmayLogo}
                  alt={siteStatus.dataMap[popupCoordinates.status].label}
                  style={{
                    width: "3rem",
                    height: "1.5rem",
                  }}
                />
              ) : (
                <Icon
                  title={
                    siteStatus.dataMap[popupCoordinates.status].label ||
                    popupCoordinates.status
                  }
                  baseClassName="fas"
                  className={siteStatus.dataMap[popupCoordinates.status].icon}
                  fontSize="small"
                  style={{
                    color: siteStatus.dataMap[popupCoordinates.status].color,
                    width: "30px",
                  }}
                />
              )}

              <p
                className="m-0"
                style={{
                  color: siteStatus.dataMap[popupCoordinates.status].color,
                  fontWeight: 800,
                }}
              >
                <Translate
                  text={siteStatus.dataMap[popupCoordinates.status].label}
                />
              </p>
              <div
                title="Edit Status"
                onClick={() => {
                  handleSidebarTypeChange(popupCoordinates, true);
                }}
              >
                <Icon
                  title="Edit Status"
                  baseClassName="fas"
                  className={"fa-solid fa-pencil cursor-pointer"}
                  fontSize="small"
                  style={{
                    color: "var(--pencil-icon-color)",
                    width: "30px",
                    fontSize: "1.3rem",
                  }}
                />
              </div>
            </div>
          )}

          <div
            className="marker-popup-top-show-more"
            onClick={() => handleSidebarTypeChange(popupCoordinates, false)}
          >
            #{popupCoordinates.ID}
          </div>

          <div
            className="marker-popup-top-show-more"
            onClick={() => handleSidebarTypeChange(popupCoordinates, false)}
          >
            <Icon
              title="Delivery Number"
              baseClassName="fas"
              className={"fa-solid fa-box"}
              fontSize="small"
              style={{
                color: "var(--text-secondary-color)",
                width: "30px",
                fontSize: "1.2rem",
              }}
            />
            {popupCoordinates["Delivery Number"]}
          </div>
        </div>

        <div
          style={{
            color: "var(--text-color)",
          }}
          onClick={() => {
            setShowPopup(false);
          }}
        >
          <Icon
            title="Close"
            baseClassName="fa"
            color="var(--text-color)"
            className={"fa-regular fa-circle-xmark cursor-pointer"}
            fontSize="small"
          />
        </div>
      </div>

      <div className="marker-popup-middle-container">
        <div className="popup-image-container">
          {siteImage !== null ? (
            <>
              <div className="popup-image-sub-container">
                <img
                  src={"data:image/png;base64, " + siteImage}
                  className="popup-image popup-image-1"
                  style={{
                    height: "27rem",
                    width: "30rem"
                  }}
                />
              </div>

              <div className="date-container">
                <p>{popupCoordinates.image_1_date}</p>
                <p id="image-2-date">{popupCoordinates.image_2_date}</p>
              </div>
            </>
          ) : (
            <DottedCircleLoader />
          )}
        </div>
      </div>

      {(popupCoordinates.maplink || popupCoordinates.sv_Link) && (
        <div className="popup-map-link-container">
          {/* <div className="popup-map-link-sub-container">
            <a
              href={popupCoordinates.sv_Link || popupCoordinates.maplink}
              target="_blank"
            >
              <Translate
                text={
                  popupCoordinates.sv_Link ? "See Street View" : "View on Map"
                }
              />
            </a>
          </div> */}

          <div
            className="popup-map-link-sub-container"
            //style={{ borderLeft: "2px solid grey" }}
            onClick={() => handleSidebarTypeChange(popupCoordinates, false)}
          >
            <Translate
              text={
                popupCoordinates.status !== "new"
                  ? "View Site Progress"
                  : "Site Info"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MarkerPopup;
