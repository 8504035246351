import React from "react";
import { Stack } from "@atlaskit/primitives";
import Image from "@atlaskit/image";
import GeotricsLogo from "../../assets/images/construction1.gif";

const EmptyStateLoader = (props) => {
  return (
    <Stack
      space="space.100"
      alignInline="center"
      //style={{ textAlign: "center"}}
    >
      <Image src={GeotricsLogo} alt="Simple example" testId="image" style={{width:"20rem"}}/>
      <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
        {props.message}
      </p>
    </Stack>
  );
};

export default EmptyStateLoader;
