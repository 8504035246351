import { Button, Fab, MenuItem, Select, FormControl,CircularProgress,Backdrop,Checkbox,ListItemText } from "@mui/material";

import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend, Title, RadialLinearScale } from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState, } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Page2 from "./BarChart";
import "../Analytics/Analytics.css";
import Page3 from "./Page3";
import bgImage from "../../assets/images/newDesign.jpg";
import page1PDF from "../../assets/images/1.jpg";
import { useSelector } from "react-redux";

// Register required Chart.js components
Chart.register(ArcElement, Tooltip, Legend, Title, RadialLinearScale);

const Analytics = () => {
    const page1 = useRef(null);
    const page2 = useRef(null);
    const page3 = useRef(null);
    const page4 = useRef(null);
    const { getAccessTokenSilently } = useAuth0();

    const zones = useSelector((state) => state.zones);
    const siteStatuses = useSelector((state) => state.siteStatus); // Assuming 'tableData' is stored in the Redux store
  
    
  
  

  console.log(zones);

    // State variables
    const [site, setSites] = useState([]);
    const [totalSite, setTotalSites] = useState(0);
    const [siteCounts, setSiteCounts] = useState({
        white: 0, yellow: 0, green: 0, orange: 0, red: 0,

    });
  
    const siteStatus = useSelector((state) => state.markerCoordinates.data);
    const [openPBar,setPBar] = useState(false);
    const [piedata, setPieData] = useState([]);
    const [Zones, setZones] = useState([]);
    const [Status,setStatus] = useState([]);
    const [size,setSize] = useState([]);
    const [landType,setLandType] = useState([]);
    const [selectedZone, setSelectedZone] = useState([]);
    const handleZoneChange = (event) => {
      setSelectedZone(event.target.value);
    };
   
    const countSiteforPage1 = (sites, selectedZone) => {
      const newwhite = [];
      const yellow = [];
      const green = [];
      const orange = [];
      const red = [];
  
      for (let index = 0; index < sites.length; index++) {
          const element = sites[index];
  
          if (!selectedZone.length || selectedZone.includes(element.Zone)) {  
              if (element.status === "new") {
                  newwhite.push(element);
              }
              if (["New-Assigned", "ground-inspection", "re-inspection"].includes(element.status)) {
                  yellow.push(element);
              }
              if (["map-issued", "pmay", "map-submitted", "govt-campus", "not-applicable"].includes(element.status)) {
                  green.push(element);
              }
              if (["notice", "do-issued", "d.o issued"].includes(element.status)) {
                  orange.push(element);
              }
              if (["demolished", "sealed", "court-case"].includes(element.status)) {
                  red.push(element);
              }
          }
      }
  
      setPieData([
          { name: "New", value: newwhite.length },
          { name: "Ground / Re - Inspection", value: yellow.length },
          { name: "Notice / D.O", value: orange.length },
          { name: "Map / PMAY / Gov.", value: green.length },
          { name: "Demolish / Sealed / Court.", value: red.length },
      ]);
  
      setSiteCounts({
          white: newwhite.length,
          yellow: yellow.length,
          green: green.length,
          orange: orange.length,
          red: red.length,
      });
  
      setTotalSites(
          !selectedZone.length 
              ? sites.length 
              : newwhite.length + yellow.length + green.length + orange.length + red.length
      );
  };
  
    
    const CustomArrowIcon = () => (
      <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 10L12 15L17 10H7Z" fill="white" />  {/* Change color by setting fill to white */}
      </svg>
    );

    const getZoneList = (sites) => {
        
       const zon = zones.data.map((zones)=>zones.Zone);
        setZones(zon);
        setSelectedZone(zon);


        
        const uniqueStatus = sites?.map(site => site.status)
            .filter((value, index, self) => self.indexOf(value) === index) || []; 
        setStatus(uniqueStatus);
        
        

    }


    useEffect(() => {
      

        setSites(siteStatus);
        setTotalSites(siteStatus.length);
        getZoneList(siteStatus);

           console.log(siteStatus);
           
       
    }, [getAccessTokenSilently,siteStatus]);
    
    
   
    useEffect(() => {
        if (site.length > 0) {
            countSiteforPage1(site, selectedZone);
        }
    }, [site, selectedZone]);
    

    
    const handleSaveAsPdf = async () => {
        setPBar(true);
        const pdf = new jsPDF('l', 'px', [800, 510]); 
    
        // Function to capture a specific page and handle visibility for page1
        const capturePage = async (ref, isHidden = false) => {
            if (ref.current) {
                // if (isHidden) {
                //     // Temporarily make page1 visible
                //     ref.current.style.display = 'block';
                // }
    
                // Capture the element as a canvas
                const canvas = await html2canvas(ref.current);
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = pdf.internal.pageSize.getWidth();
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
                // Add the captured image to the PDF
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                pdf.addPage();
    
                // if (isHidden) {
                //     // Hide page1 again after capturing it
                //     ref.current.style.display = 'none';
                // }
            }
        };
    
        // Capture page1 and temporarily show it only for PDF generation
        await capturePage(page1, false); // true indicates page1 is hidden on the web
    
        // Capture page2, page3, and page4 (visible on both web and PDF)
        await capturePage(page2); // No need to handle visibility, it stays visible
        await capturePage(page3);
        await capturePage(page4);
    
        // Remove the last page to avoid an empty page at the end
        pdf.deletePage(pdf.internal.getNumberOfPages());
    
        // Save the PDF with the file name
        pdf.save("VDA_Report.pdf");
        setPBar(false);
    };
    


    return (
        <div className="container">
        <div id="main-div">
            {/* <div className="top-bar">
               
                <Button variant="outlined" className="savePdf"    onClick={handleSaveAsPdf}   startIcon={<PictureAsPdfOutlinedIcon />}>
                    Save as Pdf
                </Button> 
                </div> */}
                <Backdrop
            sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
            open={openPBar}
            
          >
            <CircularProgress color="inherit" />
            </Backdrop>

             
            <div ref={page1}  style={{ width: "100%", padding: "10px 0", textAlign: "center",display:"block" }}>
            <div
            className="responsive-bg"
            style={{
              width: "100%",
            
              height: "55rem", // Keeps div height at 83vh
              backgroundSize: "100% 100%", // Scales image to fit both width and height of div
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            backgroundImage: `url(${page1PDF})`,
            }}
             alt="backgroundImage" >
            <div className="top-bar">
               
               <Button variant="outlined" className="savePdf"    onClick={handleSaveAsPdf}   startIcon={<PictureAsPdfOutlinedIcon />}>
                   Save as Pdf
               </Button> 
               </div>
              </div>         

              
            </div>

            <div ref={page2} style={{ 
                width: '100%',
        padding: '20px 0',
        justifyContent: 'center', 
        alignItems: 'center', 
        textAlign: 'center',}}>
                <div className="design-PieChart" 
     style={{
       backgroundImage: `url(${bgImage})`,
                 // Full width
     }}
>
  <div className="filter">
    <h1 className="subhead" style={{marginLeft:"30px"}}>
      We Identified <span>{totalSite}</span> Sites
    </h1>
    <div className="divider"></div>
    <div className="select-zone">
      <FormControl required sx={{ m: 1, minWidth: 160 }} 
  style={{
    backgroundColor: "#2B3D6C",
    border: "3px solid black",
    color: "white"
  }}>
  <Select
    multiple
    style={{ color: "white" }}
    id="zone-select"
    displayEmpty
    inputProps={{ 'aria-label': 'Without label' }}
    value={selectedZone}
    onChange={handleZoneChange}
    renderValue={()=>'Zone'}
    IconComponent={CustomArrowIcon}  // Custom arrow SVG
  >
  
    {Zones.map((zone) => (
      <MenuItem key={zone} value={zone}>
        <Checkbox checked={selectedZone.indexOf(zone) > -1} />
        <ListItemText primary={zone} />
      </MenuItem>
    ))}
  </Select>
</FormControl>

    </div>
  

   

   </div>
  <div id="SitesComponents">
    <div className="PieChart">
      <Doughnut
      className="Doughnutchart"
        data={{
          labels: piedata.map((data) => data.name),
          datasets: [{
            label: "Count",
            data: piedata.map((data) => data.value),
            backgroundColor: ["rgb(226, 226, 226)", "yellow", "orange", 'green', 'red'],
            borderWidth: 2,
          }]
        }}
        options={{
          plugins: {
            
            title: { display: true, text: "Site Status" },
            legend: { position: 'bottom',labels:{
                color:"black"
            } }
          },
          layout: {
          padding:{
            bottom: 10, // Adds space between the chart and the legend
            top: 10,
          }
          },
        
          maintainAspectRatio: true,
        }}
      />
    </div>

<div className="secondContainer">
<div className="grids-container">
     

     <div className="Cards" style={{ backgroundColor: ["rgb(226, 226, 226)", "yellow", "green", "orange", "red"] }}>
       <div className="Card-Data">
         <Fab className="fab-button" aria-label="add" >
           <h3>{siteCounts.white}</h3>
         </Fab>
         <h3 className="Card-heading">{"New"}</h3>
       </div>
     </div>
     <div className="Cards" style={{ backgroundColor: "yellow" }}>
       <div className="Card-Data">
         <Fab className="fab-button" aria-label="add" >
           <h3>{siteCounts.yellow}</h3>
         </Fab>
         <h3 className="Card-heading">{"New Assigned, Ground Inspection, Re-Inspection"}</h3>
       </div>
     </div>

</div>
<div className="Cards" style={{ backgroundColor: "green", width:"640px",marginLeft:"80px",marginTop:"10px" }}>
       <div className="Card-Data">
         <Fab className="fab-button" aria-label="add" >
           <h3>{siteCounts.green}</h3>
         </Fab>
         <h3 className="Card-heading" style={{width:"100%",marginLeft:"10px",color:"white",}}>{"Map issued, Map Submitted, N.A., PMAY, Govt. Campus"}</h3>
       </div>
     </div>
     <div className="grids-container">
     
     <div className="Cards" style={{ backgroundColor: "orange" }}>
       <div className="Card-Data">
         <Fab className="fab-button" aria-label="add" >
           <h3>{siteCounts.orange}</h3>
         </Fab>
         <h3 className="Card-heading" style={{color:"white"}}>{"Notice, D.O-Issued"}</h3>
       </div>
     </div>

     <div className="Cards" style={{ backgroundColor:   "red"}}>
       <div className="Card-Data">
         <Fab className="fab-button" aria-label="add" >
           <h3>{siteCounts.red}</h3>
         </Fab>
         <h3 className="Card-heading" style={{color:"white"}}>{"Demolished, Sealed, Court-Case"}</h3>
       </div>
     </div>


</div>
</div>
  </div>
</div>

            </div>

            {/* Bar Chart */}
            <div ref={page3} style={{ width: "100%", padding: "20px 0"}}>
                <Page2 siteData={site} Zones={Zones}  Status={Status} Sizes={size}/>
            </div>

            {/* Polar and Bar Charts */}
            <div ref={page4} style={{ width: "100%", padding: "10px 0",marginTop:"10px"}}>
                <div className="siteDetected">
                    
                    
                        <Page3 sites={site} landtypes = {landType} Zone={Zones}></Page3>

            
                </div>
            </div>
        </div>
       
        </div>
    );
};

export default Analytics;