import React, { useState } from "react";
import "./Dashboard.css";

import Translate from "../../components/Translate/Translate";
import DashboardComponent from "../../components/Dashboard/DashboardComponent";
import LabTabs from "../../components/Dashboard/Tabs";
import ColorTabs from "../../components/Dashboard/Tabs";

const Dashboard = () => {
  return (
    <div className="dashboard-main-container">
      <h1>
        <Translate text="Dashboard" />
      </h1>

      <DashboardComponent />
    {/* <ColorTabs></ColorTabs> */}
    </div>
  );
};

export default Dashboard;
