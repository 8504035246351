import React, { useState, useEffect } from "react";
import "./Translate.css";
import { useSelector } from "react-redux";
import helperFunctions from "../../utils/helper";
import DOMPurify from 'dompurify';

const Translate = ({
  text,
  targetLanguage,
  sourceLanguage = "en",
  contentEditable,
  defaultValue,
  onTextChange,
}) => {
  const [transformedText, setTransformedText] = useState("");
  const textHere = text || defaultValue;

  const user = useSelector((state) => state.user);

  // If target language is not provided, use user's language
  if (targetLanguage === undefined) {
    targetLanguage = user.language;
  }

  useEffect(() => {
    const translateAndSet = async () => {
      // Check if source and target languages are the same
      if (sourceLanguage === targetLanguage) {
        setTransformedText(textHere); // No translation needed, return original text
        return;
      }

      // Check if the text is a number before proceeding
      if (!isNaN(textHere)) {
        setTransformedText(textHere); // No translation needed, return original text
        return;
      }

      const translations =
        JSON.parse(localStorage.getItem("translations")) || {};
      const targetTranslations = translations[targetLanguage] || {};

      if (targetTranslations[textHere]) {
        // If translation is cached, use it directly
        setTransformedText(targetTranslations[textHere]);
      } else {
        // Otherwise, translate and cache
        try {
          const translatedText = helperFunctions.translateText(
            textHere,
            targetLanguage
          );

          // check if translated text is promise
          if (translatedText.then) {
            const translatedTextResolved = await translatedText;
            if (translatedTextResolved) {
              setTransformedText(translatedTextResolved);
            }
          } else if (translatedText) {
            setTransformedText(translatedText);
          } else {
            console.log("Translation failed.");
          }
        } catch (error) {
          console.error("Error: Something went wrong");
        }
      }
    };

    translateAndSet();
  }, [text, targetLanguage, sourceLanguage]);

  const sanitizedTransformedText = DOMPurify.sanitize(transformedText);

  if (contentEditable === true) {
    return (
      <div
        className={"translate" + (contentEditable ? " editable-true" : "")}
        contentEditable={contentEditable}
        onInput={(e) => {
          onTextChange && onTextChange(e.target.innerText);
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedTransformedText }}
      ></div>
    );
  }

  // if text is null or undefined, return null
  if (text === null || text === undefined) {
    return <i>{transformedText}</i>;
  }

  // console.debug("Transformed text: --------- ###### ", transformedText);
  return <>{transformedText}</>;
};

export default Translate;
