import React from "react";
import "./Loader.css"; // Import the CSS file for styling

import Spline from "@splinetool/react-spline";

const LoadingAnimation = () => {
  return (
    <div className="loading-animation">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

const Loader = () => {
  return (
    <div className="loading-container">
      {process.env.REACT_APP_ENV !== "LOCAL" && (
        <Spline
          scene="https://prod.spline.design/XREr3ippm6nuQixj/scene.splinecode"
          className="spline-loader"
          style={{
            position: "absolute",
            marginRight: "7rem",
            marginBottom: "5rem",
          }}
        />
      )}

      <div className="loading-container-heading">
        <LoadingAnimation />
      </div>
    </div>
  );
};

export default Loader;
